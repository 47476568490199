import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { CpuFill, PencilSquare, Trash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useUser } from '../auth/user';
import {
  canDelete, canUpdate, get, del,
  canReadProjectDeviceAssociation,
} from './utils';

function DevicesTable() {
  const { user } = useUser();
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    get()
      .then((devicesResponse) => {
        setDevices(devicesResponse);
      });
  }, []);

  const deleteDevice = async (deviceId) => {
    if (window.confirm('Supprimer ?') === true) {
      await del(deviceId);
      const devs = await get();
      setDevices(devs);
    }
  };

  /* eslint-disable arrow-body-style */
  const getCurrentProject = (deviceRow) => {
    const prj = deviceRow
      ?.projects
      ?.find((project) => {
        return Date.parse(project.start) < Date.now() < Date.parse(project.end);
      });
    if (!prj) return 'Aucun project en cours';
    return `${prj.project.name}`;
  };

  const rows = devices && Array.isArray(devices) ? (
    devices.map((row) => (
      <tr key={row.hw_identifier}>
        <td>{row.hw_identifier}</td>
        <td>{row.type.name}</td>
        <td>{row.status}</td>
        <td>{getCurrentProject(row)}</td>
        <td>
          <Link to={canUpdate(user) && `/devices/${row.id}`}>
            <button
              aria-label="éditer"
              className="btn btn-primary"
              disabled={!canUpdate(user)}
              type="button"
              title="éditer"
            >
              <PencilSquare />
            </button>
          </Link>
          {' '}
          <Link to={canReadProjectDeviceAssociation(user) && `/devices/${row.id}/projects`}>
            <button
              aria-label="projets associés"
              className="btn btn-success btn-outline"
              disabled={!canReadProjectDeviceAssociation(user)}
              type="button"
              title="projets associés"
            >
              <CpuFill />
            </button>
          </Link>
          {' '}
          <button
            aria-label="supprimer"
            className="btn btn-danger"
            onClick={() => deleteDevice(row.id)}
            disabled={!canDelete(user)}
            type="button"
            title="supprimer"
          >
            <Trash />
          </button>
        </td>
      </tr>
    ))
  ) : (
    // case data is empty
    <tr>
      <td>aucune donnée</td>
    </tr>
  );

  return (
    <Table striped borderless hover>
      <thead>
        <tr>
          <th>Identifiant de l&lsquo;appareil</th>
          <th>Type</th>
          <th>Statut</th>
          <th>Projet en cours</th>
          <th>Gestion</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default DevicesTable;
