import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Container } from 'react-bootstrap';
import {
  Building, Film, FolderFill, Globe, HouseDoorFill, Upload, Check,
} from 'react-bootstrap-icons';

import PropTypes, { string } from 'prop-types';
import AuthMenu from './auth/authMenuComponent';
import { userPropType } from './auth/user';
import HelpMenu from './helpMenu/helpMenu';

function Menu({ user, applicationsVersions }) {
  const [cvaturl, setCvaturl] = useState('');

  useEffect(() => {
    const getCVATURL = async () => {
      const requestOptions = {
        method: 'GET',
      };

      const rawResponse = fetch(
        `${process.env.REACT_APP_DATASTORE_URI}/cvat/link`,
        requestOptions,
      );
      return rawResponse;
    };
    getCVATURL()
      .then((res) => {
        res.json().then((resolvedResponse) => {
          const url = `${resolvedResponse}/tasks`;
          setCvaturl(url);
        });
      });
  }, []);

  function hasRole(role) {
    return user?.roles?.includes(role);
  }

  function hasAnyRole(roles) {
    return roles.some((r) => hasRole(r));
  }

  return (
    <header>
      <Navbar collapseOnSelect bg="dark" variant="dark" expanded="true">
        <Container fluid>
          <Navbar.Brand href="/">Geoptis</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav variant="tabs" className="me-auto">
              <Nav.Item>
                <Nav.Link as={Link} to="/">
                  <HouseDoorFill />
                  Accueil
                </Nav.Link>
              </Nav.Item>
              {hasRole('read_projects')
                && (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/projects">
                      <FolderFill />
                      Projets
                    </Nav.Link>
                  </Nav.Item>
                )}
              {hasRole('read_devices')
                && (
                  <Nav.Item>
                    <NavDropdown
                      title="Matériel"
                      id="basic-nav-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item as={Link} to="/devices">
                        Appareils
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/devices/types">
                        Types de matériel
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Item>
                )}
              {hasRole('read_institutions')
                && (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/institutions">
                      <Building />
                      Établissements
                    </Nav.Link>
                  </Nav.Item>
                )}
              {hasRole('read_territories')
                && (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/territories">
                      <Globe />
                      Zones géographiques
                    </Nav.Link>
                  </Nav.Item>
                )}
              {hasRole('upload')
                && (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/upload">
                      <Upload />
                      Upload
                    </Nav.Link>
                  </Nav.Item>
                )}
              {hasRole('validation_videos')
                && (
                  <Nav.Item>
                    <Nav.Link as={Link} to="/projects/validation">
                      <Check />
                      Validation
                    </Nav.Link>
                  </Nav.Item>
                )}
              {hasAnyRole(['read_territories', 'read_institutions', 'read_projects', 'read_devices'])
                && (
                  <Nav.Item>
                    <a href={cvaturl} target="_blank" rel="noreferrer" className="nav-link">
                      <Film />
                      Annotation
                    </a>
                  </Nav.Item>
                )}
            </Nav>
            <HelpMenu applicationsVersions={applicationsVersions} />
            <AuthMenu user={user} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

Menu.propTypes = {
  user: userPropType,
  applicationsVersions: PropTypes.arrayOf(PropTypes.shape({
    component_name: string,
    version: string,
  })).isRequired,
};

Menu.defaultProps = {
  user: {},
};

export default Menu;
