import { xhr } from '../../common/utils';
import AuthCheckerFactory from '../../auth/utils';

const commonUri = (id) => `${process.env.REACT_APP_BACKEND_URI}/projects/${id}/referential`;
const htmlUri = (id) => `${process.env.REACT_APP_BACKEND_URI}/projects/${id}/referential_offline`;

const get = (id) => xhr('GET', null, commonUri(id));

const del = (id) => xhr('DELETE', null, commonUri(id));

const objectName = 'projects';
const { canDelete, canUpdate, canCreate } = AuthCheckerFactory(objectName);

export {
  canDelete,
  canUpdate,
  canCreate,
  get,
  del,
  htmlUri,
};
