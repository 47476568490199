import React, { useState, useEffect } from 'react';
import { BarChart } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { getReporting, projectPropTypes } from './utils';

function ProjectReportingModal({ project }) {
  const fullScreen = false;
  const [show, setShow] = useState(false);

  const [proj, setProject] = useState(null);
  const [dataPct, setDataPct] = useState(null);
  const [dataKm, setDataKm] = useState(null);

  useEffect(() => {
    getReporting(project.id)
      .then((res) => {
        setProject(...res);
      });
  }, [project.id]);

  const twoDecimalRound = (number) => Math.round(number * 100) / 100;

  useEffect(() => {
    const colors = [
      'grey',
      'orange',
      'green',
      'blue',
    ];
    const mapper = {
      Référentiel: 'Référentiel',
      'Reste à faire': 'Reste à filmer',
      Relevé: 'Filmé',
      Vidéocodé: 'Vidéocodé',
    };
    const order = [
      'Référentiel',
      'Reste à faire',
      'Relevé',
      'Vidéocodé',
    ];
    const dataPercentage = [];
    const dataKilometers = [];
    const labels = [];
    let divider = 1;
    if (proj
      && proj.sections
      && Array.isArray(proj.sections)
      && proj.sections.length) {
      divider = proj.sections.find((x) => x.section_status === 'Référentiel')?.length || 1;
    }

    dataPercentage.push({
      data: [],
      backgroundColor: colors,
      label: 'Valeurs en pourcentage',
    });

    dataKilometers.push({
      data: [],
      backgroundColor: colors,
      label: 'Valeurs en kilomètres',
    });

    //  Ici on considère filmé tout ce qui est dans le csv donc filmé = reférentiel - reste à faire
    let newFilmedLength = 0;
    let newVideocodedLength = 0;
    if (proj?.sections.length > 1) {
      const resteAFaire = proj.sections.find((x) => x.section_status === 'Reste à faire')
        || { section_status: 'Reste à faire', length: 0 };
      const referential = proj.sections.find((x) => x.section_status === 'Référentiel')
        || { section_status: 'Référentiel', length: 0 };
      const resteAVideocoder = proj.sections.find((x) => x.section_status === 'Reste à vidéocoder')
        || { section_status: 'Reste à vidéocoder', length: (proj.sections.find((x) => x.section_status === 'Référentiel')).length };

      newFilmedLength = (referential.length - resteAFaire.length);
      newVideocodedLength = (referential.length - resteAVideocoder.length);
    }

    for (let i = 0; proj && i < order.length; i++) {
      const relevantSections = proj.sections.find((x) => x.section_status === order[i])
        || { section_status: order[i], length: 0 };
      labels.push(mapper[relevantSections.section_status]);
      if (order[i] === 'Relevé') {
        dataPercentage[0].data
          .push(twoDecimalRound((newFilmedLength / divider) * 100));
        dataKilometers[0].data
          .push(twoDecimalRound(newFilmedLength / 1000));
      } else if (order[i] === 'Vidéocodé') {
        dataPercentage[0].data
          .push(twoDecimalRound((newVideocodedLength / divider) * 100));
        dataKilometers[0].data
          .push(twoDecimalRound(newVideocodedLength / 1000));
      } else {
        dataPercentage[0].data
          .push(twoDecimalRound((relevantSections.length / divider) * 100));
        dataKilometers[0].data
          .push(twoDecimalRound(relevantSections.length / 1000));
      }
    }
    setDataPct({
      labels,
      datasets: dataPercentage,
    });
    setDataKm({
      labels,
      datasets: dataKilometers,
    });
  }, [proj]);

  const options = (title) => ({
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        text: title,
        display: true,
      },
      formatter: (value, context) => (`${context.dataIndex}: ${Math.round(value)} %`),
    },
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  );

  function handleShow() {
    setShow(true);
  }

  return proj && dataPct && dataKm && (
    <>
      <Button variant="primary" title="reporting" onClick={() => handleShow()}>
        <BarChart />
      </Button>

      <Modal show={show} fullscreen={fullScreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Reporting d&lsquo;un projet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {project.name}
          </h5>
          <Bar options={options('Valeurs en %')} data={dataPct} plugins={[ChartDataLabels]} />
          <Bar options={options('Valeurs en kilomètres')} data={dataKm} />
        </Modal.Body>
      </Modal>
    </>
  );
}

ProjectReportingModal.propTypes = {
  project: projectPropTypes.isRequired,
};

export default ProjectReportingModal;
