import React from 'react';
import { ButtonLabels } from '../../constants';
import DeviceTypeForm from './singleForm';

function DeviceTypeCreateWrapper() {
  const send = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    (async () => {
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/device_types`,
        requestOptions,
      );
      await rawResponse.json();
    })();
  };

  const title = 'Nouveau type de matériel';

  return (
    <div>
      <DeviceTypeForm
        submit={send}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default DeviceTypeCreateWrapper;
