import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DeviceStatus } from '../constants';
import { get } from './types/utils';
import '../common/style.css';

const getDeviceTypes = get;

function DeviceForm({
  data,
  submit,
  title,
  submitLabel,
  cancelLabel,
  restrictWrite,
}) {
  const defaultValues = {
    hw_identifier: '',
    status: DeviceStatus.OP,
    id: '',
    type_id: '',
  };

  let initValues = defaultValues;
  if (Object.keys(data).length) {
    initValues = { ...defaultValues, ...data };
  }

  const navigate = useNavigate();
  const navigateToDevices = () => {
    setTimeout(1000, navigate('/devices'));
  };

  const [deviceTypes, setDeviceTypes] = useState([]);
  useEffect(() => {
    getDeviceTypes()
      .then((devTypesResponse) => {
        setDeviceTypes(devTypesResponse);
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: Yup.object({
      hw_identifier: Yup.string().required('Obligatoire'),
      status: Yup.string().required('Obligatoire'),
      type_id: Yup.string().required('Obligatoire'),
    }),
    onSubmit: async (values) => {
      await submit(values);
      navigateToDevices();
    },
  });

  function markAsRequired(mark) {
    return mark ? <span>*</span> : '';
  }

  const confirmNavigation = () => {
    if (!formik.dirty) {
      navigateToDevices();
    } else {
      const confirm = window.confirm(
        "Certains changements n'ont pas été enregistrés, souhaitez-vous continuer ?",
      );
      if (confirm) {
        navigateToDevices();
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row className="mt-4">
        <Col md={6}>
          <h3 className="p-3">{title}</h3>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col md={10}>
              <label htmlFor="hw_identifier">
                Identifiant de l&lsquo;appareil&nbsp;&nbsp;
                {markAsRequired(!restrictWrite)}
              </label>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <input
                id="hw_identifier"
                className="form-control"
                type="text"
                placeholder="Identifiant"
                readOnly={restrictWrite}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.hw_identifier}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col md={12}>
              <label htmlFor="status">
                Statut&nbsp;&nbsp;
                {markAsRequired(!restrictWrite)}
              </label>
              <Form.Select
                id="status"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                isInvalid={!!formik.errors.status}
              >
                {[DeviceStatus.OP, DeviceStatus.MAINT, DeviceStatus.KO, DeviceStatus.QUIET].map(
                  (i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ),
                )}
              </Form.Select>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col md={12}>
              <label htmlFor="type_id">
                Type de matériel&nbsp;&nbsp;
                {markAsRequired(!restrictWrite)}
              </label>
              <Form.Select
                aria-label="Type de matériel"
                id="type_id"
                name="type_id"
                value={formik.values.type_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.type_id}
              >
                <option>Sélectionnez un type</option>
                {deviceTypes.map((t) => (
                  <option key={t.name} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Form.Select>

            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={2}>
          <button
            onClick={confirmNavigation}
            className="btn btn-outline-danger"
            type="button"
          >
            {cancelLabel}
          </button>
          {' '}
          <button type="submit" className="btn btn-success" disabled={!(formik.isValid && formik.dirty)}>
            {submitLabel}
          </button>
        </Col>
      </Row>
    </form>
  );
}

DeviceForm.propTypes = {
  data: PropTypes.shape({
    hw_identifier: PropTypes.string.isRequired,
  }),
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  restrictWrite: PropTypes.bool,
};

DeviceForm.defaultProps = {
  data: {},
  restrictWrite: false,
};

export default DeviceForm;
