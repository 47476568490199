import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { projectPropTypes, projectDefaultProps } from './utils';

function ProjectPicker({
  value, onChange, onBlur, projects, isInvalid, fullValue, id,
}) {
  return (
    <Form.Select
      name="project_id"
      aria-label="ProjectPicker"
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={!projects.length}
      isInvalid={isInvalid}
    >
      {projects.length && <option value={-1}>Sélectionnez un projet</option>}
      {projects.length
        && projects.map((t) => (
          <option key={t.id} value={t.id}>
            {t.name}
            {` (${t.territory.code} - ${t.territory.name})`}
            {` [${t.devices.length}/${t.device_types.reduce((prev, curr) => prev + curr.count, 0)}]`}
          </option>
        ))}
      {!projects.length && fullValue.devices && (
        <option key={fullValue.id} value={fullValue.id}>
          {fullValue.name}
          {` (${fullValue.territory.code} - ${fullValue.territory.name})`}
          {` [${fullValue.devices.length}/${fullValue.device_types.reduce((prev, curr) => prev + curr.count, 0)}]`}
        </option>
      )}
    </Form.Select>
  );
}

ProjectPicker.propTypes = {
  value: PropTypes.number.isRequired,
  fullValue: projectPropTypes,
  projects: PropTypes.arrayOf(projectPropTypes),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

ProjectPicker.defaultProps = {
  fullValue: projectDefaultProps,
  projects: [],
  isInvalid: false,
};

export default ProjectPicker;
