export function extract(videoNames: ReadonlyArray<string>): ReadonlyArray<string> {
  function compareNumericalCodes() {
    return (aCode, anotherCode) => aCode.localeCompare(
      anotherCode,
      undefined,
      { numeric: true },
    );
  }

  return videoNames.map(
    (videoName) => videoName.split('_')[6],
  ).sort(compareNumericalCodes());
}

export function getFirstAndLast(codes: ReadonlyArray<string>): string {
  return [...new Set().add(codes.at(0)).add(codes.at(-1))].join('-');
}
