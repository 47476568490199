export const DeviceStatus = {
  OP: 'Opérationnel',
  KO: 'KO',
  MAINT: 'En maintenance',
  QUIET: 'Muet',
};

export const ProjectStatus = {
  CURRENT: 'En cours',
  DONE: 'Terminé',
  DROPPED: 'Abandonné',
};

export const ProjectType = {
  DEDICATED: 'Dédié',
};

export const ProjectPriority = {
  NORMAL: 'Normale',
  PRIO: 'Prioritaire',
  NONPRIO: 'Non prioritaire',
};

export const ButtonLabels = {
  SUBMIT: 'Enregistrer',
  UPDATE: 'Mettre à jour',
  CANCEL: 'Annuler',
  DELETE: 'Supprimer',
};

export const SectionStatus = {
  TODO: 'Reste à faire',
  FILMED: 'Relevé',
  VIDEOCODED: 'Vidéocodé',
};

export const ReferentialStatus = {
  IN_PROGRESS: 'En cours',
  TERMINATED: 'Terminé',
};

export const flags = [
  { name: 'enableGeoref', isActive: true },
];
