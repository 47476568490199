import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

function DeviceTypeForm({
  data, submit, title, cancelLabel, submitLabel,
}) {
  const defaultValues = {
    name: '',
  };

  let initValues = defaultValues;
  if (data) {
    initValues = { ...defaultValues, ...data };
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Obligatoire'),
  });

  const navigate = useNavigate();
  const navigateToDeviceTypes = () => navigate('/devices/types');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema,
    onSubmit: async (values) => {
      await submit(values);
      navigateToDeviceTypes();
    },
  });

  const confirmNavigation = () => {
    if (!formik.dirty) {
      navigateToDeviceTypes();
    } else {
      const confirm = window.confirm(
        "Certains changements n'ont pas été enregistrés, souhaitez-vous continuer ?",
      );
      if (confirm) {
        navigateToDeviceTypes();
      }
    }
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      action="/device_types"
      className="form-inline"
    >
      <Col className="row justify-content-between">
        <Col md={4}>
          <h2>{title}</h2>
        </Col>

        <Col md={2}>
          <button
            onClick={confirmNavigation}
            className="btn btn-outline-danger"
            type="button"
          >
            {cancelLabel}
          </button>
          {' '}
          <button type="submit" className="btn btn-success" disabled={!(formik.isValid && formik.dirty)}>
            {submitLabel}
          </button>
        </Col>
      </Col>

      <Row mt={4} className="mt-4 justify-content-between">
        <Col md={5}>
          <label htmlFor="device-type-name">
            Nom
            {'  '}
            <span>*</span>
            <Form.Control
              type="text"
              id="device-type-name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
          </label>
        </Col>
      </Row>
    </Form>
  );
}

DeviceTypeForm.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  submit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

DeviceTypeForm.defaultProps = {
  data: {
    name: '',
  },
};

export default DeviceTypeForm;
