import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeviceTypeForm from './singleForm';
import { get, patch } from './utils';
import { ButtonLabels } from '../../constants';

function DeviceTypeUpdateWrapper() {
  const { id } = useParams();
  const [deviceType, setDeviceType] = useState();

  useEffect(() => {
    get(id)
      .then((res) => setDeviceType(res));
  }, [id]);

  const title = 'Modifier un type de matériel';

  const submit = patch(id);

  return (
    <div>
      <DeviceTypeForm
        submit={submit}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.UPDATE}
        cancelLabel={ButtonLabels.CANCEL}
        data={deviceType}
      />
    </div>
  );
}

export default DeviceTypeUpdateWrapper;
