import React, { useState, useEffect } from 'react';
import {
  Table,
} from 'react-bootstrap';
import {
  getAcquisitions,
} from '../utils';
import ValidationRow from './ValidationRow';

function ImagePage() {
  const [acquisitions, setAcquisitions] = useState([]);

  useEffect(() => {
    getAcquisitions().then((res) => {
      setAcquisitions(res.sort((a, b) => {
        if (a.uploaded_on > b.uploaded_on) {
          return -1;
        }
        if (b.uploaded_on > a.uploaded_on) {
          return 1;
        }
        return 0;
      }));
    });
  }, []);

  const onValidationDone = (id) => {
    setAcquisitions(
      acquisitions.toSpliced(acquisitions.findIndex((acq) => acq.acquisitionbatchs_id === id), 1),
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">Validation</h3>
        </div>
        <div className="col-md-6" />
      </div>
      <Table striped borderless hover>
        <thead>
          <tr>
            <th>Fichier CSV</th>
            <th>Identifiant</th>
            <th>Date Téléversement</th>
            <th>Statut</th>
            <th title="Total / A valider / Validé / Inexploitable">Vidéos (Tot/AVal/Val/Inex)</th>
            <th>Validation</th>
          </tr>
        </thead>
        <tbody>
          {
            acquisitions.length ? (
              acquisitions.map((elt) => (
                <ValidationRow
                  acquisition={elt}
                  key={elt.acquisitionbatchs_id}
                  onValidationDone={onValidationDone}
                />
              ))
            ) : (
              <tr>
                <td colSpan={5}>Aucune acquisition à afficher</td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </div>
  );
}

export default ImagePage;
