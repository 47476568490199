/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint no-unused-vars: 0 */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { BiBrain } from 'react-icons/bi';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Pagination,
  Tab,
  Tabs,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { useOutletContext, useParams } from 'react-router-dom';
import {
  get,
  getVideos,
  postGeoreferencing,
  canUpdate,
  cvatUrl,
  getKeyboards,
} from '../utils';
import KeyboardSection from './keyboard/keyboardSection';
import { useUser } from '../../auth/user';
import { extract, getFirstAndLast } from '../../common/videocodes';
import { buildMergedVideoLabel } from '../../common/videoname';

const StatusEnum = {
  CREATED: 'Créée',
  IN_PROGRESS: 'En cours',
  OK: 'Succès',
  KO: 'Echec',
  UNKNOWN: 'Unknown',
};

const VideoStatusEnum = {
  UPLOADED: 'Envoyé',
  WAITING_ON_VIDEO: 'En attente d\'envoi',
};

function Filter({
  column,
  table,
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={Number(columnFilterValue)?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old) => [
          e.target.value,
          old?.[1],
        ])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={Number(columnFilterValue)?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old) => [
          old?.[0],
          e.target.value,
        ])}
        placeholder="Max"
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Rechercher..."
      className="w-36 border shadow rounded"
    />
  );
}
function checkButton(row, keyboards) {
  const idKeyboards = keyboards.map((obj) => obj.id);
  const idRow = row.georeferencing_requests.map((obj) => obj.keyboard_id);
  if (idRow.length === 0 && idKeyboards.length === 0) {
    return true;
  }
  return idKeyboards.every((keyboardId) => idRow.includes(keyboardId));
}

function VideoTable() {
  const { user } = useUser();
  const [project, setProject] = useState();
  const [videos, setVideos] = useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = useState({});
  const [cvatLink, setCvatLink] = useState();
  const [keyboards, setKeyboards] = useState([]);
  const [load, setLoad] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getKeyboards(id).then((res) => {
      setKeyboards(res);
    });
  }, [id, load]);

  useEffect(() => {
    get(id).then((res) => setProject(res));
  }, [id]);

  const extractStatus = (processesArray) => {
    if ((processesArray.length) === 0 || !Array.isArray(processesArray)) {
      return StatusEnum.UNKNOWN;
    }
    const statusArray = processesArray.map((el) => el.status);
    const uniqueStatusArray = statusArray.filter((el, i) => statusArray.indexOf(el) === i);

    if (uniqueStatusArray.length === 1) {
      return uniqueStatusArray[0];
    }
    return StatusEnum.IN_PROGRESS;
  };

  useEffect(() => {
    getVideos(id).then((res) => {
      setRefreshTable(false);
      setVideos(
        res.merged_videos_data_list
          .map((row) => ({
            ...row,
            buttonDisabled: checkButton(row, keyboards),
            // eslint-disable-next-line max-len
            keyboard_status: extractStatus(row.georeferencing_requests),
          })),
      );
    });
  }, [id, keyboards, videos.length, refreshTable]);

  useEffect(() => {
    cvatUrl().then((res) => {
      setCvatLink(res);
    });
  }, []);

  const handleDeleteKeyboardDOM = (keyboardId) => {
    const updatedVideos = videos.map((video) => {
      const filteredKeyboard = video.georeferencing_requests
        .filter((keyboard) => keyboard.keyboard_id !== keyboardId);
      // eslint-disable-next-line no-param-reassign
      video.georeferencing_requests = filteredKeyboard;
      return video;
    });
    setVideos(updatedVideos);
  };

  const getClassFromStatus = (statusString) => {
    switch (statusString) {
      case StatusEnum.OK:
        return 'video-success';
      case StatusEnum.IN_PROGRESS:
        return 'video-doing';
      case StatusEnum.CREATED:
        return 'video-doing';
      case StatusEnum.KO:
        return 'video-failed';
      default:
        return 'video-unknown';
    }
  };

  function IndeterminateCheckbox({
    indeterminate,
    className = 'form-check-input',
    ...rest
  }) {
    const ref = useRef(null);
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate, rest.checked]);
    return (
      <input
        type="checkbox"
        ref={ref}
        className={`${className} cursor-pointer`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );
  }
  const columns = useMemo(
    () => {
      const postGeo = (cell) => {
        const vfId = cell.row.original.videofile_id;
        postGeoreferencing({ videofiles_id_list: [vfId] })
          .then((res) => {
            setRefreshTable(true);
          });
      };
      function ButtonCell(cell) {
        return (
          <Button
            // eslint-disable-next-line react/destructuring-assignment
            disabled={cell.row.original.buttonDisabled || Object.keys(rowSelection).length >= 2}
            onClick={() => postGeo(cell)}
          >
            <BiBrain />
          </Button>
        );
      }
      return [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        {
          header: 'Code(s) de la vidéo',
          enableColumnFilter: false,
          accessorFn: (originalRow) => getFirstAndLast(extract(originalRow.original_names)),
        },
        {
          header: 'Libellé de la vidéo',
          accessorFn: (originalRow) => buildMergedVideoLabel(project.name, originalRow.s3_path),
        },
        {
          header: 'Claviers et statuts',
          cell: ({ row }) => (
            <td>
              {row.original.georeferencing_requests.map(
                (georeferencingRequest) => (
                  <p className="clavier_statut">
                    {georeferencingRequest.keyboard_name}
                    {' : '}
                    {georeferencingRequest.status}
                  </p>
                ),
              )}
            </td>
          ),
          enableColumnFilter: false,
        },
        {
          header: 'Statut GéoRef',
          accessorFn: (originalRow) => originalRow.georeferencing_status,
          enableColumnFilter: false,
        },
        {
          header: 'Traitement',
          cell: ({ cell }) => ButtonCell(cell),
          enableColumnFilter: false,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, rowSelection],
  );

  const table = useReactTable({
    data: useMemo(() => videos, [videos]),
    columns,
    // Pipeline
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => !row?.original?.buttonDisabled,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const georeferencingSelected = async () => {
    const vfids = Object.keys(rowSelection).map(
      (rowsId) => table.getRow(rowsId).original.videofile_id,
    );
    postGeoreferencing({ videofiles_id_list: vfids })
      .then((res) => {
        setRefreshTable(true);
      });
  };

  if (project && !project.is_georeferencing) {
    return (
      <div>
        <Col md={12}>
          <Col md={6}>
            <h2>
              {`${project.name}`}
            </h2>
          </Col>
          <h3 className="warning-title">Ce projet n&apos;est pas un projet de géoreferencement</h3>
        </Col>
      </div>
    );
  }

  if (project && (!Array.isArray(videos) || videos.length === 0)) {
    return (
      <div>
        <Col md={12}>
          <Col md={6}>
            <h2>
              {`${project.name} - géoreférencement`}
            </h2>
          </Col>
          <KeyboardSection
            id={id}
            handleDeleteKeyboardDOM={handleDeleteKeyboardDOM}
            keyboards={keyboards}
            load={load}
            setLoad={setLoad}
          />
          <h3>Pas de vidéos</h3>
        </Col>
      </div>
    );
  }

  if (!canUpdate(user)) {
    return (
      <Col md={12}>
        <p>
          Vous n&rsquo;avez pas les droits pour éditer cette page
        </p>
      </Col>
    );
  }

  return canUpdate(user) && project && (
    <>
      <h2>
        {`${project.name} - géoreférencement`}
      </h2>
      <KeyboardSection
        id={id}
        handleDeleteKeyboardDOM={handleDeleteKeyboardDOM}
        keyboards={keyboards}
        load={load}
        setLoad={setLoad}
      />
      <div>
        <Col md={10}>
          <Row>
            <Col md={1} className="button-wrapper">
              <Button
                onClick={() => georeferencingSelected()}
                disabled={Object.keys(rowSelection).length < 2}
              >
                <BiBrain />
              </Button>
            </Col>
          </Row>
        </Col>
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          role="button"
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              header.column.getToggleSortingHandler();
                            }
                          }}
                          tabIndex={0}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {
                            {
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] || null
                          }
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter
                              column={header.column}
                              table={table}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const className = getClassFromStatus(row.original.keyboard_status);
              return (
                <tr key={row.id} className={className}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Container fluid>
          <Row>
            <Col md={10}>
              <Pagination>
                <Pagination.Prev
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                />
                {
                  (table.getState().pagination.pageIndex > 2)
                  && <Pagination.Item onClick={() => table.setPageIndex(0)}>1</Pagination.Item>
                }
                {
                  (table.getState().pagination.pageIndex > 2)
                  && <Pagination.Ellipsis disabled />
                }
                {
                  [...Array(3)].map((_, i) => {
                    const pageNum = i + table.getState().pagination.pageIndex;
                    if (pageNum < table.getPageCount()) {
                      return (
                        <Pagination.Item
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          onClick={() => table.setPageIndex(pageNum)}
                          active={pageNum === table.getState().pagination.pageIndex}
                        >
                          {pageNum + 1}
                        </Pagination.Item>
                      );
                    }
                    return null;
                  })
                }
                {
                  (table.getState().pagination.pageIndex < table.getPageCount() - 3)
                  && <Pagination.Ellipsis disabled />
                }
                {
                  (table.getState().pagination.pageIndex < table.getPageCount() - 3) && (
                    <Pagination.Item onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                      {table.getPageCount()}
                    </Pagination.Item>
                  )
                }
                <Pagination.Next
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                />
              </Pagination>
            </Col>
            <Col md={2}>
              <Form.Select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {`Afficher ${pageSize}`}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default VideoTable;
