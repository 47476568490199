import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TerritoryForm from './singleForm';
import { ButtonLabels } from '../constants';
import { get, patch } from './utils';

function TerritoriesView() {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (id !== '') {
      get(id)
        .then((resolved) => {
          setData(resolved);
        });
    }
  }, [id]);

  const defaultValues = {
    name: '',
    code: '',
  };

  const title = 'Détail de la zone géographique';
  const submit = patch(id);

  return (
    <div>
      <TerritoryForm
        data={data || defaultValues}
        submit={submit}
        restrictWrite
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default TerritoriesView;
