import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import {
  canDelete, canUpdate, del, get,
} from './utils';
import { useUser } from '../auth/user';

function InstitutionsTable() {
  const { user } = useUser();
  const [institutions, setInstitutions] = useState([]);
  useEffect(() => {
    get()
      .then((institutionsRes) => {
        setInstitutions(institutionsRes);
      });
  }, []);

  const deleteInstitution = async (institutionId) => {
    if (window.confirm('Supprimer ?') === true) {
      await del(institutionId);

      const institutionsNew = institutions.filter(
        (element) => element.id !== institutionId,
      );
      setInstitutions(institutionsNew);
    }
  };

  const rows = institutions && user && Array.isArray(institutions) ? (
    institutions.map((row) => (
      <tr key={row.regate_code}>
        <td>{row.name}</td>
        <td>{row.address}</td>
        <td>{row.regate_code}</td>
        <td>
          {row.territory.name}
          {' '}
          (
          {row.territory.code}
          )
        </td>
        <td>{row.phone_number}</td>
        <td>
          <Link to={canUpdate(user) && `/institutions/${row.id}`}>
            <button
              aria-label="éditer"
              className="btn btn-primary"
              disabled={!canUpdate(user)}
              type="button"
            >
              <PencilSquare />
            </button>
          </Link>
          {' '}
          <button
            aria-label="supprimer"
            className="btn btn-danger"
            onClick={() => deleteInstitution(row.id)}
            disabled={!canDelete(user)}
            type="button"
          >
            <Trash />
          </button>
        </td>
      </tr>
    ))
  ) : (
    // case this.state.data is empty
    <tr>
      <td>aucune donnée</td>
    </tr>
  );

  return (
    <Table striped borderless hover>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Adresse</th>
          <th>Code REGATE</th>
          <th>Zone géographique</th>
          <th>Numéro de téléphone</th>
          <th>Gestion</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default InstitutionsTable;
