import React, { useEffect, useState } from 'react';
import { Flags } from 'react-feature-flags';
import { Formik } from 'formik';
import {
  ButtonGroup, Col, Form, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../common/style.css';
import { ProjectPriority, ProjectStatus, ProjectType } from '../constants';
import ReferentialWrapper from './referential/referentialWrapper';
import TerritorySelectorWrapper from '../territory/territorySelectorWrapper';
import Institutions from './institutionsPicker';
import { projectDefaultProps, projectPropTypes } from './utils';
import { get as getInstitutions } from '../institution/utils';
import { get as getDeviceTypes } from '../device/types/utils';
import { dateToDateString } from '../common/utils';
import DeviceTypeSelector from './deviceTypeSelector';
import { userPropType } from '../auth/user';
import { creationSchema } from './projectCreationSchema';

function DisplayReferential({ id, user }) {
  if (id !== '') {
    return (
      <Col md={12} className="d-flex flex-column map-form-container">
        <ReferentialWrapper user={user} />
      </Col>
    );
  }
  return null;
}

DisplayReferential.propTypes = {
  id: PropTypes.string,
  user: userPropType,
};

DisplayReferential.defaultProps = {
  id: '',
  user: {},
};

function ProjectForm({
  id,
  data,
  submit,
  cancelLabel,
  submitLabel,
  title,
  displayReferential,
  isEditing,
}) {
  const defaultValues = {
    name: '',
    quote_number: '',
    customer_code: '',
    capture_end_date: '',
    desired_end_date: '',
    status: ProjectStatus.CURRENT,
    type: ProjectType.DEDICATED,
    initialization_form_validated: false,
    referential_validated: false,
    delivered: false,
    device_types: [],
    identifier: '',
    ref_geoptis: '',
    institution_id: 0,
    territory_id: 0,
    priority: ProjectPriority.NORMAL,
    initialization_form_validated_date: '',
    referential_validated_date: '',
    delivered_date: '',
    length: 0,
    contact: '',
    contact_backup: '',
    is_georeferencing: false,
  };

  let initValues = defaultValues;
  if (data) {
    initValues = { ...defaultValues, ...data };
  }

  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchUserInfo = async () => {
      const requestOptions = {
        method: 'GET',
      };

      return fetch(
        `${process.env.REACT_APP_BACKEND_URI}/iam/whoami`,
        requestOptions,
      );
    };
    fetchUserInfo().then((res) => {
      if (res.status >= 400) {
        setUser(null);
      } else {
        res.json().then((resolvedResponse) => {
          setUser(resolvedResponse);
        });
      }
    });
  }, []);

  // navigate to projects page
  const navigate = useNavigate();
  const navigateToProjects = () => {
    navigate('/projects');
  };

  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    getInstitutions().then((resolvedResponse) => {
      setInstitutions(resolvedResponse);
    });
  }, []);

  const [deviceTypes, setDeviceTypes] = useState([]);

  useEffect(() => {
    getDeviceTypes().then((resolvedResponse) => {
      setDeviceTypes(resolvedResponse);
    });
  }, []);

  const confirmNavigation = (formik) => {
    if (!formik.dirty) {
      navigateToProjects();
    } else {
      const confirm = window.confirm(
        'Certains changements n\'ont pas été enregistrés, souhaitez-vous continuer ?',
      );
      if (confirm) {
        navigateToProjects();
      }
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={async (values) => {
        const vals = JSON.parse(JSON.stringify(values));
        const keys = Object.keys(vals);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key.endsWith('date')) {
            vals[key] = dateToDateString(vals[key]);
          }
        }
        await submit(vals);
        navigateToProjects();
      }}
      validationSchema={creationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Form
          className="form-inline"
          onSubmit={formik.handleSubmit}
        >
          <Col className="row justify-content-between">
            <Col md={6}>
              <h3 className="p-3">{title}</h3>
            </Col>

            <Col md={6}>
              <button
                type="submit"
                className="btn btn-success float-end"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {submitLabel}
              </button>
              {' '}
              <button
                onClick={() => confirmNavigation(formik)}
                className="btn btn-outline-danger float-end"
                type="button"
              >
                {cancelLabel}
              </button>
            </Col>
          </Col>

          <hr />

          <Row className="mt-4">
            <Col md={5}>
              <Form.Group md={12}>
                <label htmlFor="customer_code" className="md-12">
                  <Col md={12}>
                    <Col md={12}>
                      Code client&nbsp;&nbsp;
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        id="customer_code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.customer_code}
                        isInvalid={!!formik.errors.customer_code}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.customer_code}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                </label>
              </Form.Group>
            </Col>

            <Col md={5} className="offset-md-1">
              <Row>
                <Col md={5}>
                  <label htmlFor="status">
                    Statut
                    <Row>
                      <ButtonGroup
                        className="btn-group btn-group-toggle"
                        data-toggle="buttons"
                        id="status"
                      >
                        {[
                          ProjectStatus.CURRENT,
                          ProjectStatus.DONE,
                          ProjectStatus.DROPPED,
                        ].map((i) => (
                          <label
                            className={
                              i === formik.values.status
                                ? 'btn btn-secondary btn-sm'
                                : 'btn btn-outline-secondary btn-sm'
                            }
                            key={i.toString()}
                            htmlFor={i.toString()}
                          >
                            <input
                              type="radio"
                              name="status"
                              className="btn-check"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={i}
                              id={i.toString()}
                            />
                            {i}
                          </label>
                        ))}
                      </ButtonGroup>
                    </Row>
                  </label>
                </Col>

                <Col md={7}>
                  <label htmlFor="priority">
                    Priorité
                    <Row>
                      <ButtonGroup>
                        {[
                          ProjectPriority.PRIO,
                          ProjectPriority.NORMAL,
                          ProjectPriority.NONPRIO,
                        ].map((i) => (
                          <label
                            className={
                              i === formik.values.priority
                                ? 'btn btn-secondary btn-sm'
                                : 'btn btn-outline-secondary btn-sm'
                            }
                            key={i.toString()}
                            htmlFor={i.toString()}
                          >
                            <input
                              type="radio"
                              name="priority"
                              className="btn-check"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={i}
                              id={i.toString()}
                            />
                            {i}
                          </label>
                        ))}
                      </ButtonGroup>
                    </Row>
                  </label>
                </Col>
              </Row>
              <Row>
                <div>
                  <Flags
                    authorizedFlags={['enableGeoref']}
                    renderOn={() => (
                      <Form.Check
                        disabled={isEditing}
                        type="switch"
                        id="is_georeferencing"
                        name="is_georeferencing"
                        label="Projet de géoreferencement"
                        checked={formik.values.is_georeferencing}
                        onChange={() => formik.setFieldValue(
                          'is_georeferencing',
                          !formik.values.is_georeferencing,
                        )}
                      />
                    )}
                    renderOff={() => (
                      <Form.Check
                        disabled
                        type="switch"
                        label="Projet de géoreferencement"
                      />
                    )}
                  />
                </div>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={5}>
              <Form.Group md={12}>
                <label htmlFor="quote_number" className="md-12">
                  <Col md={12}>
                    <Col md={12}>
                      Numéro de devis&nbsp;&nbsp;
                      <span>*</span>
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        id="quote_number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.quote_number}
                        isInvalid={!!formik.errors.quote_number}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.quote_number}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                </label>
              </Form.Group>
            </Col>

            <Col md={5} className="offset-md-1">
              <label htmlFor="institution_id">
                <Col md={12}>
                  Partenaire prise de vue
                  {'  '}
                  <span>*</span>
                  <Institutions
                    institution={(data && data.institution) || {}}
                    institutions={institutions || []}
                    formik={formik}
                    value={parseInt(formik.values.institution_id, 10)}
                    isInvalid={!!formik.errors.institution_id}
                    id="institution_id"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.institution_id}
                  </Form.Control.Feedback>
                </Col>
              </label>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={5}>
              <label htmlFor="name">
                <Col md={12}>
                  <Row>
                    <Col md={10}>
                      Libellé
                      {'  '}
                      <span>*</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        id="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        isInvalid={!!formik.errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
              </label>
            </Col>

            <Col md={5} className="offset-md-1">
              <Row>
                <Col md={12}>
                  {deviceTypes && (
                    <DeviceTypeSelector
                      options={deviceTypes}
                      projectId={data.id}
                    />
                  )}
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row className="mt-4">
                <Col md={6}>
                  <label htmlFor="territory_id">
                    <Col md={8}>
                      Zone géographique
                      {'  '}
                      <span>*</span>
                      <TerritorySelectorWrapper
                        id="territory_id"
                        name="territory_id"
                        value={parseInt(formik.values.territory_id, 10)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullValue={data && data.territory}
                        isInvalid={!!formik.errors.territory_id}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.territory_id}
                      </Form.Control.Feedback>
                    </Col>
                  </label>
                </Col>

                <Form.Group as={Col} md={6}>
                  <label htmlFor="length">
                    <Col md={8}>
                      Nombre de KM du projet
                      {'  '}
                      <span>*</span>
                      <Form.Control
                        type="text"
                        id="length"
                        name="length"
                        value={formik.values.length}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={!!formik.errors.length}
                      />

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.length}
                      </Form.Control.Feedback>
                    </Col>
                  </label>
                </Form.Group>
              </Row>
              <Row className="mt-4">
                <Col md={6}>
                  <Row>
                    <label htmlFor="capture_end_date">
                      <Col md={12}>
                        Date de fin de prise de vue&nbsp;&nbsp;
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          id="capture_end_date"
                          type="date"
                          name="capture_end_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.capture_end_date}
                          isInvalid={!!formik.errors.capture_end_date}
                        />
                      </Col>
                    </label>
                  </Row>
                </Col>

                <Col md={6}>
                  <Row>
                    <label htmlFor="desired_end_date">
                      <Col md={12}>
                        Date de restitution souhaitée&nbsp;&nbsp;
                        <span>*</span>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          id="desired_end_date"
                          type="date"
                          md={4}
                          name="desired_end_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.desired_end_date}
                          isInvalid={!!formik.errors.desired_end_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.desired_end_date}
                        </Form.Control.Feedback>
                      </Col>
                    </label>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <label htmlFor="initialization_form">
                  <Col md={10}>
                    <Row>Fiche initialisation</Row>
                    <Row>
                      <Col md={3}>
                        <Row className="mt-2">
                          <Col md={2}>
                            <div className="form-check-inline">
                              <Form.Check
                                className="position-static"
                                name="initialization_form_validated"
                                id="initialization_form_validated_ckb"
                                checked={
                                  formik.values.initialization_form_validated
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </Col>
                          <Col md={10}>valid&eacute;e le</Col>
                        </Row>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          id="initialization_form_validated_date"
                          type="date"
                          name="initialization_form_validated_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.initialization_form_validated_date}
                          isValid={
                            formik.touched.initialization_form_validated_date
                            && !formik.errors.initialization_form_validated_date
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </label>
              </Row>

              <Row className="mt-4">
                <label htmlFor="referential">
                  <Col md={10}>
                    <Row>Référentiel</Row>
                    <Row>
                      <Col md={3}>
                        <Row className="mt-2">
                          <Col md={2}>
                            <div className="form-check-inline">
                              <Form.Check
                                className="position-static"
                                name="referential_validated"
                                id="referential_validated_ckb"
                                checked={formik.values.referential_validated}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </Col>
                          <Col md={10}>valid&eacute; le</Col>
                        </Row>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          id="referential_validated_date"
                          type="date"
                          name="referential_validated_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.referential_validated_date}
                          isValid={
                            formik.touched.referential_validated_date
                            && !formik.errors.referential_validated_date
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </label>
              </Row>

              <Row className="mt-4">
                <label htmlFor="delivery">
                  <Col md={10}>
                    <Row>Livrable</Row>
                    <Row>
                      <Col md={3}>
                        <Row className="mt-2">
                          <Col md={2}>
                            <div className="form-check-inline">
                              <Form.Check
                                className="position-static"
                                name="delivered"
                                id="delivered_validated_ckb"
                                checked={formik.values.delivered}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </Col>
                          <Col md={10}>restitu&eacute; le</Col>
                        </Row>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          id="delivered_date"
                          type="date"
                          name="delivered_date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.delivered_date}
                          isValid={formik.touched.delivered_date
                            && !formik.errors.delivered_date}
                        />
                      </Col>
                    </Row>
                  </Col>
                </label>
              </Row>
              <Row>
                <label htmlFor="contact">
                  <Col md={10}>
                    <Col md={12}>
                      Contact
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        id="contact"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contact}
                        isInvalid={!!formik.errors.contact}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.contact}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                </label>
              </Row>
              <Row>
                <label htmlFor="contact_backup">
                  <Col md={10}>
                    <Col md={12}>
                      Suppl&eacute;ant
                    </Col>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        id="contact_backup"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contact_backup}
                        isInvalid={!!formik.errors.contact_backup}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.contact_backup}
                      </Form.Control.Feedback>
                    </Col>
                  </Col>
                </label>
              </Row>
            </Col>
            <Col md={6} className="d-flex">
              {displayReferential && (
                <DisplayReferential id={id} user={user} />
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

ProjectForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: projectPropTypes,
  submit: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  displayReferential: PropTypes.bool,
  isEditing: PropTypes.bool,
};

ProjectForm.defaultProps = {
  id: '',
  data: projectDefaultProps,
  displayReferential: false,
  isEditing: false,
};

export default ProjectForm;
