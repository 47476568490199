import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeviceForm from './singleForm';
import { ButtonLabels, DeviceStatus } from '../constants';
import { get, patch } from './utils';

function ViewWrapper() {
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    if (id !== '') {
      get(id)
        .then((resolved) => {
          setData(resolved);
        });
    }
  }, [id]);

  const defaultValues = {
    hw_identifier: 'default hw identifier',
    satus: DeviceStatus.OP,
    id: '',
  };

  const title = "Détail de l'appareil";
  const submit = patch(id);

  return (
    <div>
      <DeviceForm
        data={data || defaultValues}
        submit={submit}
        restrictWrite
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default ViewWrapper;
