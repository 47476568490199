import * as React from 'react';
import { Link } from 'react-router-dom';
import InstitutionsTable from './allTable';
import { canCreate } from './utils';
import { useUser } from '../auth/user';

function InstitutionsList() {
  const { user } = useUser();
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">Établissements</h3>
        </div>
        <div className="col-md-6">
          {false && (
            <button className="btn btn-outline-info float-end" type="button">
              Exporter
            </button>
          )}
          {canCreate(user) && (
            <Link to="/institutions/new">
              <button className="btn btn-primary float-end" type="button">
                Créer
              </button>
            </Link>
          )}
        </div>
      </div>

      <InstitutionsTable />
    </div>
  );
}

export default InstitutionsList;
