import React from 'react';
import { ButtonLabels } from '../constants';
import DeviceForm from './singleForm';
import { post } from './utils';

function CreateWrapper() {
  const title = 'Nouvel Appareil';

  return (
    <div>
      <DeviceForm
        submit={post}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default CreateWrapper;
