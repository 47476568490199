import React from 'react';
import { ButtonLabels } from '../constants';
import { post } from './utils';
import ProjectForm from './singleForm';

function CreateWrapper() {
  const title = 'Nouveau contrat';

  return (
    <div>
      <ProjectForm
        id=""
        submit={post}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
        displayReferential={false}
        data={{}}
      />
    </div>
  );
}

export default CreateWrapper;
