import { xhr } from '../common/utils';

interface VideoDto {
  videofile_id: number;
  videofile_upload_status: string;
  acquisitionbatch_id: number;
  original_name: string;
  s3_path: string;
  s3_thumbnails_path: string;
}

interface CountVideoByStatus {
  videofile_upload_status: string;
  count: number;
}

interface AcquisitionDto {
  acquisitionbatchs_id: number;
  uploaded_on: Date,
  buffer: number,
  s3_path: string,
  hash: string,
  status: string,
  videos: Array<CountVideoByStatus>
}

const getPendingAcquisitions = (): Promise<ReadonlyArray<AcquisitionDto>> => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/acquisitions?selected_status=En attente de vidéo&selected_status=En cours`;
  return xhr('GET', null, uri);
};

const getPendingVideosForAcquisition = (acquisitionId: number)
  : Promise<ReadonlyArray<VideoDto>> => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/acquisitions/${acquisitionId}/videos?selected_status=En attente d'envoi`;
  return xhr('GET', null, uri);
};

const getFilename = (s3Path) => s3Path.split('/').pop();

function hasAtLeastOneVideoWaitingOnUpload(acquisition): boolean {
  return acquisition.videos.find(
    (videoCountByStatus) => (
      videoCountByStatus.videofile_upload_status === 'En attente d\'envoi'
      && videoCountByStatus.count > 0
    ),
  );
}

export default async function getAcquisitionsWithPendingVideos() {
  try {
    const acquisitionsWithPendingUpload = await getPendingAcquisitions();
    return await Promise.all(acquisitionsWithPendingUpload.filter(
      (acquisition) => hasAtLeastOneVideoWaitingOnUpload(acquisition),
    ).map(async (acquisition) => {
      const pendingVideos = await getPendingVideosForAcquisition(
        acquisition.acquisitionbatchs_id,
      );

      return {
        acquisitionName: getFilename(acquisition.s3_path),
        pendingVideoCodes: pendingVideos.map((_) => _.original_name),
      };
    }));
  } catch (error) {
    console.error('Error fetching acquisitions with pending videos:', error);
    throw error;
  }
}
