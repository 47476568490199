/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Form, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
function CheckBoxSelect({ options, handleChange }) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    setChecked(options.map(() => true));
  }, [options])

  const toggle = async (i) => {
    const r = checked.map((v, idx) => {
      return i === idx ? !v : !!v;
    })
    await setChecked(r);
  };

  /* const handleSelection = (i) => {
    const val = options[i];
    checked[i] = !checked[i];
    setChecked(checked);
    if (selectedValues.includes(val)) {
      setSelectedValues(selectedValues.filter((v) => v !== val));
    } else {
      setSelectedValues(selectedValues.concat([val]));
    }
    handleChange(selectedValues);
  }; */

  return (
    <Dropdown>
      <Dropdown.Toggle size='sm' variant='outline-secondary'>
        Filtrer
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((opt, i) => (
          <Dropdown.Item key={`${opt}`} onClick={() => { toggle(i); handleChange(i); } } >
              <Form.Check checked={checked[i]} readOnly style={{ "display": 'inline-block'}}/>
              {' '}
              {opt}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

CheckBoxSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CheckBoxSelect;
