import React, { useState } from 'react';
import {
  Button, Container, Row, Col,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { delKeyboard } from '../../utils';
import KeyboardForm from './keyboardAddForm';
import KeyboardList from './keyboardList';
import { ButtonLabels } from '../../../constants';

function KeyboardSection({
  // eslint-disable-next-line react/prop-types
  id, handleDeleteKeyboardDOM, keyboards, load, setLoad,
}) {
  const [show, setShow] = useState(false);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const handleCloseModalAfterSubmit = () => {
    setShow(false);
    setLoad(!load);
  };

  const handleDeleteKeyboard = async (keyboardId) => {
    if (window.confirm('Supprimer ?') === true) {
      await delKeyboard(keyboardId);
      handleDeleteKeyboardDOM(keyboardId);
      setLoad(!load);
    }
  };

  return (
    <>
      <h3>Claviers</h3>
      <Container>
        <Row className="justify-content-center">
          <KeyboardList keyboards={keyboards} handleDeleteKeyboard={handleDeleteKeyboard} />
        </Row>
        <Row className="justify-content-end ">
          <Col md={3} className="text-end mt-3">
            <Button onClick={handleShowModal}>Ajouter un clavier</Button>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un clavier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <KeyboardForm id={id} handleCloseModal={handleCloseModalAfterSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {ButtonLabels.CANCEL}
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  );
}

export default KeyboardSection;

KeyboardSection.propTypes = {
  id: PropTypes.string.isRequired,
  handleDeleteKeyboardDOM: PropTypes.func.isRequired,
};
