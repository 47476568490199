import React from 'react';
import { ButtonLabels } from '../../constants';
import LinkedProjectsForm from './linkedProjectsForm';
import { post } from './utils';

function LinkedProjectsCreate() {
  return (
    <LinkedProjectsForm
      title="Associer le matériel à un projet"
      submitLabel={ButtonLabels.SUBMIT}
      cancelLabel={ButtonLabels.CANCEL}
      submit={post}
    />
  );
}

export default LinkedProjectsCreate;
