import React from 'react';
import { Link } from 'react-router-dom';
import AllTable from './allTable';
import { canCreate, canRead } from './utils';
import { useUser } from '../auth/user';

function TerritoriesList() {
  const { user } = useUser();
  if (!canRead(user)) {
    return null;
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">Zones géographiques</h3>
        </div>
        <div className="col-md-6">
          {canCreate(user) && (
            <Link to="/territories/new">
              <button className="btn btn-primary float-end" type="button">
                Créer
              </button>
            </Link>
          )}
        </div>
      </div>

      <AllTable user={user} />
    </div>
  );
}

export default TerritoriesList;
