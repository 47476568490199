import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import {
  canDelete, canUpdate, del, get, getRoles, postRole,
} from './utils';
import { useUser } from '../auth/user';

function AllTable() {
  const { user } = useUser();
  const [territories, setTerritories] = useState([]);

  /* eslint-disable */
  const [territoriesRoles, setTerritoriesRoles] = useState([]);

  const [territoriesMerged, setTerritoriesMerged] = useState([]);

  useEffect(() => {
    get().then((res) => setTerritories(res));
  }, []);

  useEffect(() => {
    getRoles().then((res) => setTerritoriesRoles(res));
  }, []);

  useEffect(() => {
    const res = territories.map((val) => {
      if (territoriesRoles.includes(`geo_zone_${val.code}`)) {
        val.role = true;
      } else {
        val.role = false;
      }
      return val;
    });
    setTerritoriesMerged(res);
  }, [territories, territoriesRoles]);

  const columns = useMemo(() => [
    {
      Header: 'Nom',
      accessor: 'name',
    }, {
      Header: 'Identifiant',
      Cell: ({ cell }) => {
        const { role, id, code } = cell.row.original;
        return (
          role
            ? (<p>{code} ✔️</p>)
            : (<a onClick={() => postRole(id)}>{code} ❌</a>)
        )
      }
    }, {
      Header: 'Rôle Keycloak',
      accessor: (r) => `geo_zone_${r.code}`,
    }, {
      Header: 'Gestion',
      /* eslint-disable react/no-unstable-nested-components */
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell }) => {
        // eslint-disable-next-line react/prop-types
        const { id } = cell.row.original;
        return (
          <div>
            <Link to={canUpdate(user) && `/territories/${id}`}>
              <button
                className="btn btn-primary"
                disabled={!canUpdate(user)}
                type="button"
              >
                <PencilSquare />
              </button>
            </Link>
            {' '}
            <button
              className="btn btn-danger"
              onClick={async () => {
                if (window.confirm('Supprimer ?') === true) {
                  await del(id);
                  get()
                    .then((res) => {
                      setTerritories(res);
                    });
                }
              }}
              disabled={!canDelete(user)}
              type="button"
            >
              <Trash />
            </button>
          </div>
        );
      },
    },
  ], [user]);

  const defaultColumn = React.useMemo(
    () => ({
    }),
    [],
  );

  const {
    /* getTableProps, */
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: useMemo(() => territoriesMerged, [territoriesMerged]),
    defaultColumn,
  },
    useSortBy
  );

  if (!Array.isArray(territoriesMerged) || territoriesMerged.length === 0) {
    return null;
  }

  return (
    <Table striped borderless hover>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div>
                  {column.render('Header')}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default AllTable;
