import React, { useCallback } from 'react';
import {
  MapContainer,
  TileLayer,
  Polyline,
  LayersControl,
} from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { Trash, Download } from 'react-bootstrap-icons';
import { del, htmlUri } from './utils';
import { canUpdate } from '../utils';
import './leaflet.css';
import { useUser } from '../../auth/user';
import { colors } from '../../common/utils';

function ReferentialPolyline(sections, color, key, name) {
  const pathOptions = { color };
  if (name === 'Référentiel' || name === 'Reste à filmer') {
    return (
      <LayersControl.Overlay checked name={`<div style="background-color:${color}; display:inline-block; width:20px;">&nbsp;</div> ${name}`} key={key}>
        <Polyline pathOptions={pathOptions} positions={sections} />
      </LayersControl.Overlay>
    );
  }
  return (
    <LayersControl.Overlay name={`<div style="background-color:${color}; display:inline-block; width:20px;">&nbsp;</div> ${name}`} key={key}>
      <Polyline pathOptions={pathOptions} positions={sections} />
    </LayersControl.Overlay>
  );
}

function ReferentialViewer({ sections, bounds }) {
  const { id } = useParams();
  const { user } = useUser();
  // Screenshot requires useCallback to refer to the map node
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const map = useCallback(() => {
  }, []);

  function makePolylines(arrays) {
    const mapper = {
      Référentiel: 'Référentiel',
      'Reste à filmer': 'Reste à faire',
      Filmé: 'Relevé',
      "En attente d'upload": 'À intégrer',
      Vidéocodé: 'Vidéocodé',
      Inexploitable: 'Inexploitable',
      'Référentiel brut': 'raw_referential',
    };
    const polylineProperties = [];
    const mapperKeys = Object.keys(mapper);

    // Filmé = relevé + vidéocodé + à intégrer
    for (let i = 0; i < mapperKeys.length; i++) {
      if (mapperKeys[i] === 'Filmé') {
        const filmedArray = [].concat(
          arrays[mapper[mapperKeys[i]]] || [],
          arrays[mapper[mapperKeys[i + 1]]] || [],
          arrays[mapper[mapperKeys[i + 2]]] || [],
        );
        polylineProperties.push({
          sections: filmedArray,
          color: colors[i],
          index: i,
          label: mapperKeys[i],
        });
      } else {
        polylineProperties.push({
          sections: arrays[mapper[mapperKeys[i]]] || [],
          color: colors[i],
          index: i,
          label: mapperKeys[i],
        });
      }
    }

    return (
      <LayersControl position="topright">
        {polylineProperties.map((sectionData) => ReferentialPolyline(
          sectionData.sections,
          sectionData.color,
          sectionData.index,
          sectionData.label,
        ))}
      </LayersControl>
    );
  }

  const deleteReferential = async () => {
    if (window.confirm('Supprimer le référentiel ?')) {
      const res = await del(id);
      if (res.status >= 400) {
        alert('Error during upload');
      } else {
        window.location.reload(false);
      }
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(htmlUri(id), '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Col md={12} style={{ flexGrow: 1 }} className="d-flex flex-column map-container">
      <Row className="mt-4">
        <Col md={6}>
          <p className="title">Référentiel</p>
        </Col>
      </Row>
      <MapContainer
        bounds={bounds}
        scrollWheelZoom
        style={{ flexGrow: 1 }}
        className="d-flex flex-column"
        ref={map}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://dev-gme-ws.geoptis.com/raster-material/{z}/{x}/{y}.png "
          style={{ minHeight: '100px', height: '100%' }}
        />
        {makePolylines(sections)}
        <Control prepend position="topleft">
          <Button
            className="btn btn-success float-end"
            onClick={() => openInNewTab()}
          >
            <Download />
          </Button>
        </Control>
        <Control prepend position="topleft">
          <Button
            className="btn btn-danger float-end"
            onClick={() => deleteReferential(id)}
            disabled={!canUpdate(user)}
          >
            <Trash />
          </Button>
        </Control>
      </MapContainer>
    </Col>
  );
}

ReferentialViewer.propTypes = {
  /* center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired, */
  sections: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }),
      ),
    ),
  ).isRequired,
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};

export default ReferentialViewer;
