import { Formik } from 'formik';
import React from 'react';
import {
  Button,
  Form,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import validatorClavierSchema from './schemas';
import { ButtonLabels } from '../../../constants';
import { postKeyboard } from '../../utils';

function KeyboardForm({ id, handleCloseModal }) {
  const defaultValues = {
    keyboard_name: '',
    keyboard_json: '',
  };

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={async (form) => {
        // eslint-disable-next-line no-useless-escape
        const data = JSON.stringify(JSON.parse(form.keyboard_json)).replaceAll('"', '\"');
        await postKeyboard(
          {
            project_id: id,
            keyboard_name: form.keyboard_name,
            data,
          },
        );
        handleCloseModal();
      }}
      validate={async (values) => {
        const errors = {};
        try {
          const valuesToCheck = await JSON.parse((values.keyboard_json));
          await validatorClavierSchema.validate({
            keyboard_json: valuesToCheck,
            keyboard_name: values.keyboard_name,
          });
          const keyboardClassName = valuesToCheck.map((el) => el.name);
          if (keyboardClassName.includes('Potelet')) {
            if (!keyboardClassName.includes('Potelet_group')) {
              errors.keyboard_json = 'Invalide, contient la classe Potelet donc doit contenir une classe Potelet_group';
            }
          }
          if (keyboardClassName.includes('Barrière de ville')) {
            if (!keyboardClassName.includes('Barrière de ville_group')) {
              errors.keyboard_json = 'Invalide, contient la classe Barrière de ville doit contenir une classe Barrière de ville_group';
            }
          }
        } catch (err) {
          errors.keyboard_json = `Invalide, ${err.message}`;
        }
        return errors;
      }}

    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <FormLabel>
            Nom du clavier
          </FormLabel>
          <FormControl
            type="text"
            id="keyboard_name"
            onChange={formik.handleChange}
            value={formik.values.keyboard_name}
            isInvalid={!!formik.errors.keyboard_name}
          />
          <FormLabel>
            JSON du clavier
          </FormLabel>
          <Form.Control
            as="textarea"
            id="keyboard_json"
            rows={5}
            onChange={formik.handleChange}
            value={formik.values.keyboard_json}
            placeholder="Copier-coller le json représentant le clavier"
            isInvalid={!!formik.errors.keyboard_json}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.keyboard_json}
          </Form.Control.Feedback>
          <Button type="submit">{ButtonLabels.SUBMIT}</Button>
        </Form>
      )}
    </Formik>
  );
}

export default KeyboardForm;

KeyboardForm.propTypes = {
  id: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
