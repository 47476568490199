function AuthCheckerFactory(objectName) {
  const can = (user, verb, objName) => {
    if (!user || Object.keys(user).length === 0) return false;
    return user.roles.includes(`${verb}_${objName}`) || user.roles.includes('admin');
  };

  return {
    canUpdate: (user) => can(user, 'update', objectName),
    canDelete: (user) => can(user, 'delete', objectName),
    canRead: (user) => can(user, 'read', objectName),
    canCreate: (user) => can(user, 'create', objectName),
  };
}

export default AuthCheckerFactory;
