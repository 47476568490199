import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Eye } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { projectPropTypes } from './utils';

function ProjectModal({ project }) {
  const fullScreen = false;
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <>
      <Button variant="primary" title="détails" onClick={() => handleShow()}>
        <Eye />
      </Button>

      <Modal show={show} fullscreen={fullScreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Détails d&lsquo;un projet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {project.name}
          </h5>
          <Table striped>
            <tbody>
              <tr>
                <td>
                  Code client
                </td>
                <td>
                  {project.customer_code || 'Non disponible'}
                </td>
              </tr>
              <tr>
                <td>
                  Numéro de devis
                </td>
                <td>
                  {project.quote_number}
                </td>
              </tr>
              <tr>
                <td>
                  Zone géographique
                </td>
                <td>
                  {`${project.territory.name} - ${project.territory.code}`}
                </td>
              </tr>
              <tr>
                <td>
                  Longueur du projet
                </td>
                <td>
                  {`${project.length} km`}
                </td>
              </tr>
              <tr>
                <td>
                  Date de fin de capture
                </td>
                <td>
                  {project.capture_end_date || 'Non disponible'}
                </td>
              </tr>
              <tr>
                <td>
                  Projet de géoreférencement
                </td>
                <td>
                  {project.is_georeferencing ? 'OUI' : 'NON'}
                </td>
              </tr>
              <tr>
                <td>
                  Fiche initialisation
                </td>
                <td>
                  {project.initialization_form_validated
                    ? `Validée le ${project.initialization_form_validated_date}`
                    : 'Non validée'}
                </td>
              </tr>
              <tr>
                <td>
                  Référentiel
                </td>
                <td>
                  {project.referential_validated
                    ? `Validé le ${project.referential_validated_date}`
                    : 'Non validé'}
                </td>
              </tr>
              <tr>
                <td>
                  Livré
                </td>
                <td>
                  {project.delivered
                    ? `Livré le ${project.delivered_date}`
                    : 'Non livré'}
                </td>
              </tr>
              <tr>
                <td>
                  Statut
                </td>
                <td>
                  {project.status}
                </td>
              </tr>
              <tr>
                <td>
                  Priorité
                </td>
                <td>
                  {project.priority}
                </td>
              </tr>
              <tr>
                <td>
                  Partenaire de prise de vue
                </td>
                <td>
                  {project.institution.name}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}

ProjectModal.propTypes = {
  project: projectPropTypes.isRequired,
};

export default ProjectModal;
