/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint no-unused-vars: 0 */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import {
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { BiBrain } from 'react-icons/bi';
import { ImDownload, ImFolderDownload } from 'react-icons/im';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Pagination,
} from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useParams } from 'react-router-dom';
import {
  get,
  getVideos,
  postTriangulation,
  canUpdate,
} from '../utils';
import { useUser } from '../../auth/user';
import { extract, getFirstAndLast } from '../../common/videocodes';

const StatusEnum = {
  CREATED: 'Créée',
  IN_PROGRESS: 'En cours',
  SUCCEEDED: 'Succès',
  FAILED: 'Echec',
  UNKNOWN: 'Unknown',
};
const MergedVideoStatus = {
  MERGED: 'Concatené',
  CVAT_VALIDATED: 'validé CVAT',
};

function Filter({
  column,
  table,
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={Number(columnFilterValue)?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old) => [
          e.target.value,
          old?.[1],
        ])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={Number(columnFilterValue)?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old) => [
          old?.[0],
          e.target.value,
        ])}
        placeholder="Max"
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Rechercher..."
      className="w-36 border shadow rounded"
    />
  );
}

// eslint-disable-next-line react/prop-types
function VideoTable() {
  const { user } = useUser();
  const [project, setProject] = useState();
  const [videos, setVideos] = useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [ToastShow, setToastShow] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    get(id).then((res) => setProject(res));
  }, [id]);

  const extractBestProcessCandidate = (processesArray) => {
    if (!Array.isArray(processesArray)) {
      return [];
    }
    return processesArray.sort((a, b) => {
      if (a.status === StatusEnum.FAILED) {
        return 1;
      }
      if (b.status === StatusEnum.FAILED) {
        return -1;
      }
      if (Date.parse(a.creation_date) < Date.parse(b.creation_date)) {
        return 1;
      }
      return -1;
    });
  };

  useEffect(() => {
    getVideos(id).then((res) => {
      // eslint-disable-next-line consistent-return
      function triangulationButton(row) {
        if (project?.is_georeferencing) {
          if (row?.georeferencing_requests.length !== 0) {
            const rowSuccess = row.georeferencing_requests.filter(
              (r) => r.status === StatusEnum.SUCCEEDED || r.status === StatusEnum.IN_PROGRESS,
            );
            const resp = !rowSuccess.every(
              (georef) => georef.cvat_task_completed,
            );
            if (resp) {
              setToastShow(resp);
            }
            return resp;
          }
          setToastShow(true);
          return true;
        }
        if (row.status !== MergedVideoStatus.CVAT_VALIDATED) {
          setToastShow(true);
          return true;
        }
        return false;
      }

      function filterVideosByCvatTask(row) {
        if (project?.is_georeferencing) {
          if (row?.georeferencing_requests.length !== 0) {
            const resp = row.georeferencing_requests.every(
              (georef) => georef.cvat_task_completed === true,
            );
            return resp;
          }
          if (row.georeferencing_status === 'Non traité') {
            return false;
          }
          return true;
        }
        if (row.status === MergedVideoStatus.CVAT_VALIDATED) {
          return true;
        }
        return false;
      }

      setVideos(
        res.merged_videos_data_list
          .filter((r) => (filterVideosByCvatTask(r)))
          .map((row) => ({
            ...row,
            // eslint-disable-next-line max-len
            buttonDisabled: triangulationButton(row),
            best_triangulation_process: extractBestProcessCandidate(row.triangulation_requests)[0],
            downloadButton:
              // eslint-disable-next-line max-len
              extractBestProcessCandidate(row.triangulation_requests)[0]?.status !== StatusEnum.SUCCEEDED,
          })),
      );
    });
  }, [id, project, videos.length]);

  function updateAllVideos(rows) {
    const updatedVideos = videos.map((video) => {
      const matchingRow = rows.find((row) => row.videofile_id === video.videofile_id);
      if (matchingRow) {
        return {
          ...video,
          best_triangulation_process: matchingRow,
        };
      }
      return video;
    });
    setVideos(updatedVideos);
  }

  const updateVideo = useCallback((vfid, vids, data) => vids.map((row) => {
    if (row.videofile_id === vfid) {
      return {
        ...row,
        best_triangulation_process: data,
        buttonDisabled: true,
      };
    }
    return row;
  }), []);

  const getClassFromStatus = (statusString) => {
    switch (statusString) {
      case StatusEnum.SUCCEEDED:
        return 'video-success';
      case StatusEnum.IN_PROGRESS:
      case StatusEnum.CREATED:
        return 'video-doing';
      case StatusEnum.FAILED:
        return 'video-failed';
      default:
        return 'video-unknown';
    }
  };

  function IndeterminateCheckbox({
    indeterminate,
    className = 'form-check-input',
    ...rest
  }) {
    const ref = useRef(null);
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate, rest.checked]);
    return (
      <input
        type="checkbox"
        ref={ref}
        className={`${className} cursor-pointer`}
        {...rest}
      />
    );
  }

  const columns = useMemo(
    () => {
      const postTrian = (cell) => {
        const vfId = cell.row.original.videofile_id;
        postTriangulation({ videofiles_id_list: [vfId] })
          .then((res) => {
            setVideos(updateVideo(vfId, videos, res[0]));
          });
      };
      // eslint-disable-next-line react/no-unstable-nested-components
      function ButtonCell(cell) {
        return (
          <Button
            // eslint-disable-next-line react/destructuring-assignment
            disabled={cell.row.original.buttonDisabled || Object.keys(rowSelection).length >= 2}
            onClick={() => postTrian(cell)}
          >
            <BiBrain />
          </Button>
        );
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      function DownloadButton(cell) {
        // eslint-disable-next-line react/destructuring-assignment
        const row = cell.row.original;
        return (
          <Button
            disabled={row.downloadButton}
            href={`${process.env.REACT_APP_BACKEND_URI}/videos/triangulation/${row.best_triangulation_process?.id}`}
            target="_blank"
          >
            <ImDownload />
          </Button>
        );
      }
      return [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
        },
        {
          header: 'Code(s) de la vidéo',
          enableColumnFilter: false,
          accessorFn: (originalRow) => getFirstAndLast(extract(originalRow.original_names)),
        },
        {
          header: 'Libellé de la vidéo',
          accessorFn: (originalRow) => {
            const projectName = project.name;
            const path = originalRow.s3_path.split('/').pop();
            const desiredString = path.replace('.mp4', '');
            return `${projectName}_${desiredString}`;
          },
        },
        {
          header: 'Status',
          /* eslint-disable arrow-body-style */
          accessorFn: (originalRow) => {
            if (originalRow.best_triangulation_process) {
              const bp = originalRow.best_triangulation_process;
              return bp.status;
            }
            return 'À traiter';
          },
          enableColumnFilter: false,
        },
        {
          header: 'Traitement',
          cell: ({ cell }) => ButtonCell(cell),
          enableColumnFilter: false,
        },
        {
          header: 'Téléchargement',
          cell: ({ cell }) => DownloadButton(cell),
          enableColumnFilter: false,
        },
      ];
    },
    [videos, project, rowSelection, updateVideo],
  );

  const table = useReactTable({
    data: useMemo(() => videos, [videos]),
    columns,
    // Pipeline
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => !row?.original?.buttonDisabled,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const triangulationSelected = async () => {
    const vfids = Object.keys(rowSelection).map(
      (rowsId) => table.getRow(rowsId).original.videofile_id,
    );
    postTriangulation({ videofiles_id_list: vfids })
      .then((results) => {
        return updateAllVideos(results);
      });
  };

  if (project && (!Array.isArray(videos) || videos.length === 0)) {
    return (
      <div>
        <Col md={10}>
          <Col md={6}>
            <h2>
              {`${project.name} - triangulation`}
            </h2>
          </Col>
          <h3>Pas de vidéos</h3>
        </Col>
      </div>
    );
  }

  return canUpdate(user) && project && (
    <div>
      <Col md={10}>
        <Col md={6}>
          <h2>
            {`${project.name} - triangulation`}
          </h2>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={3} className="button-wrapper">
              <Button
                onClick={() => triangulationSelected()}
                disabled={Object.keys(rowSelection).length < 2}
              >
                <BiBrain />
              </Button>
              <Button
                href={`${process.env.REACT_APP_BACKEND_URI}/videos/triangulation/project/${id}`}
                target="_blank"
              >
                <ImFolderDownload />
              </Button>

            </Col>
          </Row>
        </Col>
      </Col>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          role="button"
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }
                          onClick={header.column.getToggleSortingHandler()}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              header.column.getToggleSortingHandler();
                            }
                          }}
                          tabIndex={0}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {
                            {
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] || null
                          }
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter
                              column={header.column}
                              table={table}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const status = row.original.best_triangulation_process?.status;
            const className = getClassFromStatus(status);
            return (
              <tr key={row.id} className={className}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Container fluid>
        <Row>
          <Col md={10}>
            <Pagination>
              <Pagination.Prev
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              />
              {
                (table.getState().pagination.pageIndex > 2)
                && <Pagination.Item onClick={() => table.setPageIndex(0)}>1</Pagination.Item>
              }
              {
                (table.getState().pagination.pageIndex > 2)
                && <Pagination.Ellipsis disabled />
              }
              {
                [...Array(3)].map((_, i) => {
                  const pageNum = i + table.getState().pagination.pageIndex;
                  if (pageNum < table.getPageCount()) {
                    return (
                      <Pagination.Item
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        onClick={() => table.setPageIndex(pageNum)}
                        active={pageNum === table.getState().pagination.pageIndex}
                      >
                        {pageNum + 1}
                      </Pagination.Item>
                    );
                  }
                  return null;
                })
              }
              {
                (table.getState().pagination.pageIndex < table.getPageCount() - 3)
                && <Pagination.Ellipsis disabled />
              }
              {
                (table.getState().pagination.pageIndex < table.getPageCount() - 3) && (
                  <Pagination.Item onClick={() => table.setPageIndex(table.getPageCount() - 1)}>
                    {table.getPageCount()}
                  </Pagination.Item>
                )
              }
              <Pagination.Next
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              />
            </Pagination>
          </Col>
          <Col md={2}>
            <Form.Select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {`Afficher ${pageSize}`}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <ToastContainer position="bottom-end" className="p-3">
          <Toast onClose={() => setToastShow(false)} show={ToastShow} bg="warning">
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Avertissement</strong>
            </Toast.Header>
            <Toast.Body>
              Impossible de lancer le traitement sur les vidéos
              en attente de la validation des tâches CVAT correspondantes.
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Container>
    </div>
  );
}

export default VideoTable;
