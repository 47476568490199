import React from 'react';
import { Form } from 'react-bootstrap';
import { territoryPropType } from './utils';

function ReadOnlyTerritorySelector({ fullValue }) {
  return (
    <Form.Select
      aria-label="Zone géographique"
      id="territory_id"
      name="territory_id"
      value={fullValue.id}
      disabled
    >
      <option>Sélectionnez une zone</option>
      <option key={fullValue.id} value={fullValue.id}>
        {fullValue.code}
        {' - '}
        {fullValue.name}
      </option>
    </Form.Select>
  );
}

ReadOnlyTerritorySelector.propTypes = {
  fullValue: territoryPropType.isRequired,
};

export default ReadOnlyTerritorySelector;
