import React, { useState, useEffect } from 'react';
import {
  Route,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  redirect,
} from 'react-router-dom';

import Menu from './menu';
import Home from './home';
import ProjectsList from './project/allView';
import ProjectCreate from './project/singleCreate';
import ProjectView from './project/singleEdit';
import DevicesList from './device/allView';
import DeviceView from './device/singleView';
import DevicesTypesList from './device/types/allView';
import DevicesTypesCreate from './device/types/singleWrapper';
import DevicesTypesView from './device/types/singleUpdate';
import DeviceCreate from './device/singleCreate';
import InstitutionsList from './institution/allView';
import InstitutionCreate from './institution/singleCreate';
import InstitutionView from './institution/singleView';
import ReferentialWrapper from './project/referential/referentialWrapper';
import LinkedProjectsList from './device/linked-projects/linkedProjectsList';
import LinkedProjectsCreate from './device/linked-projects/linkedProjectsCreate';
import LinkedProjectsView from './device/linked-projects/linkedProjectsView';
import TerritoriesList from './territory/allView';
import TerritoriesView from './territory/singleView';
import TerritoriesCreate from './territory/singleCreate';
import AnonymizationPage from './project/aiProcessing/anonymization';
import ValidationPage from './project/aiProcessing/thumbnailsValidation';
import GeoreferencingPage from './project/aiProcessing/georeferencing';
import TriangulationPage from './project/aiProcessing/triangulation';
import { xhr } from './common/utils';
import { ApplicationVersion } from './helpMenu/helpMenu';
import UploaderWrapper from './upload/uploaderWrapper';

const getApplicationsVersions = (): Promise<Array<ApplicationVersion>> => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/app/versions`;
  return xhr('GET', null, uri);
};

function Layout() {
  const [user, setUser] = useState();
  const [applicationsVersions, setApplicationsVersions] = useState([]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const requestOptions = {
        method: 'GET',
      };

      const rawResponse = fetch(
        `${process.env.REACT_APP_BACKEND_URI}/iam/whoami`,
        requestOptions,
      );
      return rawResponse;
    };
    fetchUserInfo()
      .then((res) => {
        if (res.status >= 400) {
          setUser(null);
          redirect('/');
        } else {
          res.json().then((resolvedResponse) => {
            setUser(resolvedResponse);
          });
        }
      });
  }, []);
  useEffect(() => {
    getApplicationsVersions().then((fetchedVersions) => {
      setApplicationsVersions(
        fetchedVersions,
      );
    });
  }, []);
  return (
    <>
      <Menu user={user} applicationsVersions={applicationsVersions} />
      <main>
        {user && (
          <Outlet context={{ user }} />
        )}
      </main>
    </>
  );
}

const router = createBrowserRouter([{
  element: <Layout />,
  children: createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/projects/new" element={<ProjectCreate />} />
      <Route path="/projects/:id" element={<ProjectView />} />
      <Route path="/projects/:id/anonymization" element={<AnonymizationPage type="anonymization" />} />
      <Route path="/projects/:id/georeferencing" element={<GeoreferencingPage type="georeferencing" />} />
      <Route path="/projects/:id/triangulation" element={<TriangulationPage type="triangulation" />} />
      <Route path="/projects/validation" element={<ValidationPage />} />
      <Route
        path="/projects/:id/referential"
        element={<ReferentialWrapper />}
      />
      <Route path="/projects" element={<ProjectsList />} />
      <Route path="/devices/new" element={<DeviceCreate />} />
      <Route path="/devices/:id" element={<DeviceView />} />
      <Route path="/devices/:deviceId/projects" element={<LinkedProjectsList />} />
      <Route path="/devices/:deviceId/projects/new" element={<LinkedProjectsCreate />} />
      <Route path="/devices/:deviceId/projects/:deviceProjectAssocId" element={<LinkedProjectsView />} />
      <Route path="/devices" element={<DevicesList />} />
      <Route
        path="/devices/types"
        element={<DevicesTypesList />}
      />
      <Route
        path="/devices/types/new"
        element={<DevicesTypesCreate />}
      />
      <Route
        path="/devices/types/:id"
        element={<DevicesTypesView />}
      />
      <Route
        path="/institutions"
        element={<InstitutionsList />}
      />
      <Route
        path="/institutions/new"
        element={<InstitutionCreate />}
      />
      <Route
        path="/institutions/:id"
        element={<InstitutionView />}
      />
      <Route
        path="/territories"
        element={<TerritoriesList />}
      />
      <Route
        path="/territories/:id"
        element={<TerritoriesView />}
      />
      <Route
        path="/territories/new"
        element={<TerritoriesCreate />}
      />
      <Route
        path="/upload"
        element={<UploaderWrapper />}
      />
    </>,
  ),
}]);

function Panoptes() {
  return <RouterProvider router={router} />;
}

export default Panoptes;
