import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FilePond } from 'react-filepond';
import { unstable_usePrompt as usePrompt, useBeforeUnload } from 'react-router-dom';
import { getBearerTokenFromCookie } from '../common/utils';

import 'filepond/dist/filepond.min.css';

function VideoUploader() {
  const server = {
    url: '/datastore/v1.0/files',
    process: {
      headers: {
        'x-customheader': '',
        'x-request-id': uuidv4(),
        Authorization: getBearerTokenFromCookie(),
      },
    },
  };

  const message = 'Téléversement en cours, voulez-vous vraiment quitter la page et annuler le téléversement ?';

  const fp = useRef(null);

  const [uploadInprogress, setUploadInprogress] = useState(false);

  usePrompt({
    message,
    when: ({ currentLocation, nextLocation }) => uploadInprogress
      && currentLocation.pathname !== nextLocation.pathname,
  });

  useBeforeUnload((e) => {
    e.returnValue = message;
  });

  const uploadWithPriority = async (pondRef) => {
    setUploadInprogress(true);
    const currtFiles = pondRef.current.getFiles();
    const csvFiles = currtFiles.filter((f) => f.fileExtension === 'csv');
    const otherFiles = currtFiles.filter((f) => f.fileExtension !== 'csv');
    const csvUpload = await Promise.all(csvFiles.map((f) => pondRef.current.processFile(f)));
    const otherUpload = await Promise.all(otherFiles.map((f) => pondRef.current.processFile(f)));
    setUploadInprogress(false);
    return Promise.all([csvUpload, otherUpload]);
  };

  return (
    <FilePond
      allowMultiple
      allowReorder={false}
      server={server}
      ref={fp}
      statuts
      name="file"
      instantUpload={false}
      labelIdle='Déposez les fichiers ici ou <span class="filepond--label-action"> Parcourez votre ordinateur </span>'
      labelInvalidField="Certains fichiers sont invalides"
      labelFileLoading="Chargement"
      labelFileLoadError="Erreur pendant le chargement"
      labelFileProcessing="Envoi"
      labelFileProcessingComplete="Envoi réussi : fichier en cours de traitement..."
      labelFileProcessingAborted="Envoi annulé"
      labelFileProcessingError="Erreur pendant l'envoi"
      labelFileProcessingRevertError="Erreur pendant l'annulation"
      labelFileRemoveError="Erreur pendant la suppression"
      labelTapToCancel="Cliquez ici pour annuler"
      labelTapToRetry="Cliquez ici pour réessayer"
      labelTapToUndo="Cliquez pour annuler"
      labelButtonRemoveItem="Supprimer"
      labelButtonAbortItemLoad="Annuler le chargement"
      labelButtonRetryItemLoad="Réessayer le chargement"
      labelButtonAbortItemProcessing="Annuler le traitement"
      labelButtonUndoItemProcessing="Défaire le traitement"
      labelButtonRetryItemProcessing="Réessayer le traitement"
      labelButtonProcessItem="Envoyer"
      itemInsertLocation={(a, b) => {
        if (!(a.file && b.file)) return 0;
        if (a.fileExtension === 'csv' && b.fileExtension === 'csv') {
          return 0;
        }
        if (b.fileExtension === 'csv') {
          return 1;
        }
        if (a.fileExtension === 'csv') {
          return -1;
        }
        return 0;
      }}
      onupdatefiles={() => {
        uploadWithPriority(fp);
      }}

    />
  );
}

export default VideoUploader;
