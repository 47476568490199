import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import TerritorySelectorWrapper from '../territory/territorySelectorWrapper';
import { institutionDefaultProps, institutionPropType } from './utils';
import '../common/style.css';

function InstitutionForm({
  data, submit, title, submitLabel, cancelLabel,
}) {
  const defaultValues = {
    name: '',
    address: '',
    regate_code: '',
    phone_number: '',
    territory_id: 0,
  };

  let initValues = defaultValues;
  if (data) {
    initValues = { ...defaultValues, ...data };
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Obligatoire'),
    address: Yup.string().required('Obligatoire'),
    regate_code: Yup.string(),
    phone_number: Yup.string().required('Obligatoire'),
    territory_id: Yup.number().positive().required('Obligatoire'),
  });

  const navigate = useNavigate();
  const navigateToInstitutions = () => {
    navigate('/institutions');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema,
    onSubmit: async (values) => {
      await submit(values);
      navigateToInstitutions();
    },
  });

  const confirmNavigation = () => {
    if (!formik.dirty) {
      navigateToInstitutions();
    } else {
      const confirm = window.confirm(
        "Certains changements n'ont pas été enregistrés, souhaitez-vous continuer ?",
      );
      if (confirm) {
        navigateToInstitutions();
      }
    }
  };

  return (
    <Form
      onSubmit={formik.handleSubmit}
      action="/institutions"
      className="form-inline"
    >
      <Col className="row justify-content-between">
        <Col md={4}>
          <h2>{title}</h2>
        </Col>

        <Col md={2}>
          <button
            onClick={confirmNavigation}
            className="btn btn-outline-danger"
            type="button"
          >
            {cancelLabel}
          </button>
          {' '}
          <button type="submit" className="btn btn-success" disabled={!(formik.isValid && formik.dirty)}>
            {submitLabel}
          </button>
        </Col>
      </Col>

      <Row mt={4} className="mt-4 justify-content-between">
        <Col md={5}>
          <label htmlFor="name">
            Nom
            {'  '}
            <span>*</span>
            <Form.Control
              type="text"
              id="name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              isInvalid={!!formik.errors.name}
            />
          </label>
        </Col>
      </Row>
      <Row mt={4} className="mt-4 justify-content-between">
        <Col md={5}>
          <label htmlFor="address">
            Adresse
            {'  '}
            <span>*</span>
            <Form.Control
              as="textarea"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              isInvalid={!!formik.errors.address}
            />
          </label>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={5}>
          <label htmlFor="territory_id">
            Zone géographique
            {'  '}
            <span>*</span>
            <TerritorySelectorWrapper
              id="territory_id"
              name="territory_id"
              value={parseInt(formik.values.territory_id, 10)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.territory_id}
            />
          </label>
        </Col>
      </Row>

      <Row mt={4} className="mt-4 justify-content-between">
        <Col md={5}>
          <label htmlFor="regate_code">
            Code REGATE
            <Form.Control
              type="text"
              id="regate_code"
              name="regate_code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.regate_code}
              isInvalid={!!formik.errors.regate_code}
            />
          </label>
        </Col>
      </Row>
      <Row mt={4} className="mt-4 justify-content-between">
        <Col md={5}>
          <label htmlFor="phone_number">
            Numéro de téléphone
            {'  '}
            <span>*</span>
            <Form.Control
              type="text"
              id="phone_number"
              name="phone_number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              isInvalid={!!formik.errors.phone_number}
            />
          </label>
        </Col>
      </Row>
    </Form>
  );
}

InstitutionForm.propTypes = {
  data: institutionPropType,
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

InstitutionForm.defaultProps = {
  data: institutionDefaultProps,
};

export default InstitutionForm;
