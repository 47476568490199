import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import KeyboardDisplay from './keyboardDisplay';

function KeyboardList({ keyboards, handleDeleteKeyboard }) {
  return (
    <>
      {keyboards.map((keyboard) => (
        <Col md={2} className="text-center mt-1" key={keyboard.id}>
          <KeyboardDisplay
            keyboard={keyboard}
            handleDeleteKeyboard={handleDeleteKeyboard}
          />
        </Col>
      ))}
    </>
  );
}

export default KeyboardList;

KeyboardList.propTypes = {
  keyboards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  handleDeleteKeyboard: PropTypes.func.isRequired,
};
