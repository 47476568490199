import * as React from 'react';
import { Link } from 'react-router-dom';
import DeviceTypesTable from './allTable';
import { canCreate } from '../../project/utils';
import { useUser } from '../../auth/user';

function DevicesList() {
  const { user } = useUser();
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">Types de matériel</h3>
        </div>
        <div className="col-md-6">
          {false && (
            <button className="btn btn-outline-info float-end" type="button">
              Exporter
            </button>
          )}
          {canCreate(user) && (
            <Link to="/devices/types/new">
              <button className="btn btn-primary float-end" type="button">
                Créer
              </button>
            </Link>
          )}
        </div>
      </div>

      <DeviceTypesTable />
    </div>
  );
}

export default DevicesList;
