import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';

const userPropType = PropTypes.shape({
  preferred_username: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.string),
});

const func = () => 0;

export { userPropType, func };

export function useUser() {
  return useOutletContext();
}
