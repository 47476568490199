import React from 'react';
import { ButtonLabels } from '../constants';
import InstitutionForm from './singleForm';
import { post } from './utils';

function InstitutionCreateWrapper() {
  const title = 'Nouvel établissement';

  return (
    <div>
      <InstitutionForm
        submit={post}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default InstitutionCreateWrapper;
