const xhr = (method, data, uri) => {
  const body = data ? JSON.stringify(data) : null;
  const requestOptions = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  };

  return (async () => {
    const rawResponse = await fetch(
      uri,
      requestOptions,
    );
    return rawResponse.json();
  })();
};

const getBearerTokenFromCookie = () => {
  const tokenValueFromCookie = document.cookie.split('; ').find((i) => i.startsWith('token'))?.split('=')[1];
  if (!tokenValueFromCookie) {
    window.alert('Connexion expirée, veuillez vous reconnecter');
    // will auto auth if less than 5min from last keycloak auth and other call in progress
    window.open(`${process.env.REACT_APP_BACKEND_URI}/iam/logout`, '_self');
  }
  return `Bearer ${tokenValueFromCookie}`;
};

const xhrWithToken = (method, data, uri) => {
  const body = data ? JSON.stringify(data) : null;
  const requestOptions = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getBearerTokenFromCookie(),
    },
    body,
  };

  return (async () => {
    const rawResponse = await fetch(
      uri,
      requestOptions,
    );
    return rawResponse.json();
  })();
};

const curry = (func) => function setId(a) {
  return function setData(b) {
    return func(a, b);
  };
};

const dateToDateString = (date) => {
  if (date) {
    return `${date}T00:00:00.000Z`;
  }
  return null;
};

const dateTimeStringToDate = (dateString) => {
  if (dateString == null) return null;
  const [tmp] = dateString.split('T');
  const tmpDate = new Date(tmp);
  // Dates are stored as UTC in DB, force display proper date
  const prevDayDate = new Date(tmpDate.getTime() + 12 * 3600 * 1000);
  return `${prevDayDate.getFullYear()}-${prevDayDate.toLocaleString('default', { month: '2-digit' })}-${prevDayDate.toLocaleString('default', { day: '2-digit' })}`;
};

const dateTimeStringToFrLocaleDate = (dateString) => {
  const tmp = dateTimeStringToDate(dateString);
  if (tmp == null) return null;
  return tmp.split('-').reverse().join('-');
};

const colors = ['black', 'red', 'blue', 'grey', 'green', 'purple', 'brown'];

export {
  curry,
  xhr,
  getBearerTokenFromCookie,
  xhrWithToken,
  dateToDateString,
  dateTimeStringToFrLocaleDate,
  dateTimeStringToDate,
  colors,
};
