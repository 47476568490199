import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import ProjectsTable from './allTable';
import { canCreate } from './utils';
import { useUser } from '../auth/user';

function ProjectsList() {
  const { user } = useUser();
  const [tableData, setTableData] = useState([]);

  const csvData = useMemo(() => {
    if (tableData.length !== 0) {
      const x = tableData.map((d) => Object.values(d));
      x.unshift(['Libellé', 'Code client', 'Zone géographique', 'Priorité', 'Fin de capture', 'Date de fin souhaitée', 'Statut', 'Matériel', '']);
      return x;
    }
    return [];
  }, [tableData]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">Projets</h3>
        </div>
        <div className="col-md-6">

          <CSVLink data={csvData} filename={`projets_${new Date().toLocaleDateString()}.csv`}>
            <Button variant="outline-success" className="float-end">
              Export excel
            </Button>
          </CSVLink>
          {canCreate(user) && (
            <Link to="/projects/new">
              <button className="btn btn-primary float-end" type="button">
                Créer
              </button>
            </Link>
          )}
        </div>
      </div>

      <ProjectsTable setData={setTableData} />
    </div>
  );
}

export default ProjectsList;
