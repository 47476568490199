import PropTypes from 'prop-types';
import { territoryPropType } from '../territory/utils';
import { institutionPropType } from '../institution/utils';
import { ProjectStatus, ProjectType, ProjectPriority } from '../constants';
import { xhr, xhrWithToken, curry } from '../common/utils';
import AuthCheckerFactory from '../auth/utils';

const objectName = 'projects';
const { canDelete, canUpdate, canCreate } = AuthCheckerFactory(objectName);

const get = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/projects`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const getReporting = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/reporting/${id}`;
  return xhr('GET', null, uri);
};

const getAdvancement = () => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/reporting`;
  return xhr('GET', null, uri);
};

const getVideos = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/${id}/videos`;
  return xhr('GET', null, uri);
};

const getVideosToValidate = () => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/to_validate`;
  return xhr('GET', null, uri);
};
const getAcquisitions = () => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/acquisitions?selected_status=En cours&selected_status=En attente de vidéo&selected_status=À valider`;
  return xhr('GET', null, uri);
};
const getVideosToValidatedByAcquisition = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/acquisitions/${id}/videos?selected_status=Envoyé`;
  return xhr('GET', null, uri);
};

const validatedVideo = (id) => {
  const uri = `${process.env.REACT_APP_DATASTORE_URI}/videos/is_valid/${id}`;
  return xhrWithToken('PUT', null, uri);
};

const rejectedVideo = (id) => {
  const uri = `${process.env.REACT_APP_DATASTORE_URI}/videos/is_unexploitable/${id}`;
  return xhrWithToken('PUT', null, uri);
};

const cvatUrl = () => {
  const uri = `${process.env.REACT_APP_DATASTORE_URI}/cvat/link`;
  return xhrWithToken('GET', null, uri);
};

const getThumbnail = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/thumbnails/${id}`;
  return uri;
};

const postGeoreferencing = (data) => {
  const uri = `${process.env.REACT_APP_DATASTORE_URI}/videos/georeferencing_request/`;
  return xhrWithToken('POST', data, uri);
};

const pvPatch = (id, data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/${id}`;
  return xhr('PATCH', data, uri);
};

const patch = curry(pvPatch);

const del = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/${id}`;
  return xhr('DELETE', null, uri);
};

const post = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects`;
  return xhr('POST', data, uri);
};

const postTriangulation = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/create_triangulation/`;
  return xhr('POST', data, uri);
};

const getTriangulationResults = (triangulationId) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/triangulation/${triangulationId}`;
  return xhr('GET', null, uri);
};

const getAllTriangulationResults = (projectId) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/triangulation/project/${projectId}`;
  return xhr('GET', null, uri);
};

const postAnonymization = (videoId, distance) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/create_anonymization/${videoId}/${distance}`;
  return xhr('POST', null, uri);
};

const getAnonymizationResults = (anonId) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/anonymization/${anonId}`;
  return xhr('GET', null, uri);
};

const getAllAnonymizationResults = (projectId) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/anonymization/project/${projectId}`;
  return xhr('GET', null, uri);
};

const postAnonymizationProject = (projectId, distance) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/videos/create_anonymization/project/${projectId}/${distance}`;
  return xhr('POST', null, uri);
};

const getKeyboards = (projectId) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/${projectId}/keyboards`;
  return xhr('GET', null, uri);
};

const postKeyboard = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/keyboard`;
  return xhr('POST', data, uri);
};

const delKeyboard = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/projects/keyboard/${id}`;
  return xhr('DELETE', null, uri);
};

const projectPropTypes = PropTypes.shape({
  name: PropTypes.string,
  number: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  desired_end_date: PropTypes.string,
  quote_number: PropTypes.string,
  status: PropTypes.string,
  max_device_count: PropTypes.number,
  type: PropTypes.string,
  initialization_form_validated: PropTypes.bool,
  referential_validated: PropTypes.bool,
  delivered: PropTypes.bool,
  identifier: PropTypes.string,
  ref_geoptis: PropTypes.string,
  institution_id: PropTypes.number,
  territory_id: PropTypes.number,
  priority: PropTypes.string,
  initialization_form_validated_date: PropTypes.string,
  referential_validated_date: PropTypes.string,
  delivered_date: PropTypes.string,
  length: PropTypes.number,
  territory: territoryPropType,
  institution: institutionPropType,
  is_georeferencing: PropTypes.bool,
});

const projectDefaultProps = {
  name: '',
  number: '',
  start_date: '',
  end_date: '',
  desired_end_date: '',
  quote_number: '',
  status: ProjectStatus.CURRENT,
  max_device_count: 0,
  type: ProjectType.DEDICATED,
  initialization_form_validated: false,
  referential_validated: false,
  delivered: false,
  identifier: '',
  ref_geoptis: '',
  institution_id: 0,
  territory_id: 0,
  priority: ProjectPriority.NORMAL,
  initialization_form_validated_date: '',
  referential_validated_date: '',
  delivered_date: '',
  length: 0,
  institution: {},
  territory: {},
};

const sectionPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  geometry: PropTypes.string.isRequired,
});

export {
  canUpdate,
  canDelete,
  canCreate,
  getAdvancement,
  getReporting,
  getVideos,
  getVideosToValidate,
  getVideosToValidatedByAcquisition,
  getAcquisitions,
  getThumbnail,
  postGeoreferencing,
  postAnonymization,
  postAnonymizationProject,
  getAllAnonymizationResults,
  getAnonymizationResults,
  postTriangulation,
  getTriangulationResults,
  getAllTriangulationResults,
  cvatUrl,
  get,
  post,
  patch,
  validatedVideo,
  rejectedVideo,
  del,
  getKeyboards,
  postKeyboard,
  delKeyboard,
  projectPropTypes,
  projectDefaultProps,
  sectionPropTypes,
};
