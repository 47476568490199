import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TerritorySelector from './territorySelector';
import ReadOnlyTerritorySelector from './territorySelectorReadOnly';
import { territoryPropType, get } from './utils';

function TerritorySelectorWrapper({
  onChange, onBlur, value, fullValue, isInvalid,
}) {
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    get()
      .then((resolvedResponse) => {
        const sorted = resolvedResponse.sort((a, b) => {
          const codePostalANumber = parseInt(a.code, 10);
          const codePostalBNumber = parseInt(b.code, 10);
          if (codePostalANumber < codePostalBNumber) {
            return -1;
          }
          if (codePostalANumber > codePostalBNumber) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setTerritories(sorted);
      });
  }, []);

  if (territories) {
    return (
      <TerritorySelector
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        territories={territories}
        isInvalid={isInvalid}
      />
    );
  }
  return <ReadOnlyTerritorySelector fullValue={fullValue} />;
}

TerritorySelectorWrapper.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  fullValue: territoryPropType,
  isInvalid: PropTypes.bool.isRequired,
};

TerritorySelectorWrapper.defaultProps = {
  value: 0,
  fullValue: {},
};

export default TerritorySelectorWrapper;
