import * as React from 'react';
import ReportingView from './project/reportingView';

function HomePage() {
  return (
    <div>
      <div style={{ textAlign: 'left' }}>
        <img src={`${process.env.PUBLIC_URL}/full_logo.png`} alt="geoptis logo" style={{ maxWidth: '350px' }} />
      </div>
      <ReportingView />
    </div>
  );
}

export default function Panel() {
  return HomePage();
}
