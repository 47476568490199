import PropTypes from 'prop-types';
import { xhr, curry } from '../common/utils';
import AuthCheckerFactory from '../auth/utils';

const objectName = 'territories';
const {
  canRead, canDelete, canUpdate, canCreate,
} = AuthCheckerFactory(objectName);

const get = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/territories`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const getRoles = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/territories_roles`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const postRole = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/territories_roles/${id}`;
  return xhr('POST', null, uri);
};

const pvPatch = (id, data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/territories/${id}`;
  return xhr('PATCH', data, uri);
};

const patch = curry(pvPatch);

const del = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/territories/${id}`;
  return xhr('DELETE', null, uri);
};

const post = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/territories`;
  return xhr('POST', data, uri);
};

const territoryPropType = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
});

export {
  canRead,
  canUpdate,
  canDelete,
  canCreate,
  get,
  getRoles,
  patch,
  del,
  post,
  postRole,
  territoryPropType,
};
