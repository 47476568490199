import Nav from 'react-bootstrap/Nav';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React from 'react';
import PropTypes, { string } from 'prop-types';
import './helpMenu.css';
import About from './about';

export type ApplicationVersion = {
  component_name: string,
  version: string
};

export default function HelpMenu({ applicationsVersions }) {
  const [showAboutSection, setShowAboutSection] = React.useState(false);

  const helpMenuIcon = () => (
    <QuestionCircleFill
      className="help-menu-icon"
    />
  );

  return (
    <Nav className="d.flex help-menu">
      <NavDropdown
        title={helpMenuIcon()}
        className="help-menu-dropdown"
        menuVariant="dark"
        aria-label="boutton de menu d'aide"
      >
        <NavDropdown.Item
          title="A propos"
          onClick={() => setShowAboutSection(true)}
          aria-label="boutton de sous menu à propos"
        >
          À propos
        </NavDropdown.Item>
      </NavDropdown>
      <About
        show={showAboutSection}
        applicationsVersions={applicationsVersions}
        onHide={() => setShowAboutSection(false)}
      />
    </Nav>
  );
}

HelpMenu.propTypes = {
  applicationsVersions: PropTypes.arrayOf(PropTypes.shape({
    component_name: string,
    version: string,
  })).isRequired,
};
