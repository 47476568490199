import PropTypes from 'prop-types';
import AuthCheckerFactory from '../auth/utils';
import { xhr, curry } from '../common/utils';

const objectName = 'devices';
const {
  canRead, canDelete, canUpdate, canCreate,
} = AuthCheckerFactory(objectName);
const {
  canRead: canReadProject, canUpdate: canUpdateProject,
} = AuthCheckerFactory('projects');

const canReadProjectDeviceAssociation = (user) => {
  return canRead(user) && canReadProject(user);
};

const canUpdateProjectDeviceAssociation = (user) => {
  return canRead(user) && canUpdateProject(user);
};

const get = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/devices`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const pvPatch = (id, data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/devices/${id}`;
  return xhr('PATCH', data, uri);
};

const patch = curry(pvPatch);

const del = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/devices/${id}`;
  return xhr('DELETE', null, uri);
};

const post = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/devices`;
  return xhr('POST', data, uri);
};

const devicePropTypes = PropTypes.shape({
  hw_identifier: PropTypes.string.isRequired,
  life_signs: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

export {
  canRead, canDelete, canUpdate, canCreate,
  canReadProjectDeviceAssociation,
  canUpdateProjectDeviceAssociation,
  get, patch, del, post,
  devicePropTypes,
};
