import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
  institutionDefaultProps,
  institutionPropType,
} from '../institution/utils';

function Institutions({
  institution, institutions, formik, value, isInvalid, id,
}) {
  return (
    <Form.Select
      aria-label="Partenaire de prise de vue"
      id={id}
      value={value}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={!institutions.length}
      isInvalid={isInvalid}
    >
      {institutions.length && <option value={-1}>Sélectionnez un établissement</option>}
      {institutions.length
        && institutions.map((t) => (
          <option key={t.id} value={t.id}>
            {t.name}
          </option>
        ))}
      {!institutions.length && (
        <option key={institution.id} value={institution.id}>
          {institution.name}
        </option>
      )}
    </Form.Select>
  );
}

Institutions.propTypes = {
  institution: institutionPropType,
  institutions: PropTypes.arrayOf(institutionPropType).isRequired,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,
  value: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

Institutions.defaultProps = {
  institution: institutionDefaultProps,
};

export default Institutions;
