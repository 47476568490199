import React, { useEffect, useState } from 'react';
import getAcquisitionsWithPendingVideos from './utils';
import PendingVideoCodesPerAcquisitionName
  from './pendingVideoCodesPerAcquisitionName';
import VideoUploader from './uploader';
import { userPropType } from '../auth/user';

export default function UploaderWrapper({ user }) {
  const [pendingVideosPerAcquisitionName, setPendingVideosPerAcquisitionName] = useState(
    [],
  );

  useEffect(() => {
    getAcquisitionsWithPendingVideos().then(
      (acquisitionsWithPendingVideos) => setPendingVideosPerAcquisitionName(
        acquisitionsWithPendingVideos,
      ),
    );
  }, []);

  return (
    <>
      <VideoUploader user={user} />
      {pendingVideosPerAcquisitionName.length > 0 && (
        <PendingVideoCodesPerAcquisitionName
          pendingVideosPerAcquisitionName={pendingVideosPerAcquisitionName}
        />
      )}
    </>
  );
}

UploaderWrapper.propTypes = {
  user: userPropType,
};

UploaderWrapper.defaultProps = {
  user: {},
};
