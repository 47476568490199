/* eslint-disable camelcase */
import React,
{
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  Form,
  Table,
  Button,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import {
  GeoAltFill,
  PencilSquare,
  PersonVideo3,
  PinMapFill,
  Trash,
  SignpostSplit,
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useTable, useSortBy, useFilters } from 'react-table';
import {
  canDelete, canUpdate, get, del,
} from './utils';
import 'react-bootstrap/Form';
import 'bootstrap-select';
import { dateTimeStringToFrLocaleDate } from '../common/utils';
import '../common/style.css';
import CheckBoxSelect from '../common/CheckBoxSelect';
import ProjectModal from './singleView';
import ProjectReportingModal from './singleReportingView';
import { useUser } from '../auth/user';

/* const columnPropTypes = PropTypes.shape({
  filterValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  setFilter: PropTypes.func,
  preFilteredRows: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number }),
  ),
  id: PropTypes.string,
}); */

/* eslint-disable react/jsx-props-no-spreading */

function DefaultColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, setFilter },
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Rechercher..."
    />
  );
}

// DefaultColumnFilter.propTypes = PropTypes.shape({ column: columnPropTypes });

function SelectColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: {
    // eslint-disable-next-line react/prop-types
    filterValue, setFilter, preFilteredRows, id,
  },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const opts = new Set();
    // eslint-disable-next-line react/prop-types
    preFilteredRows.forEach((row) => {
      opts.add(row.values[id]);
    });
    return [...opts.values()];
  }, [id, preFilteredRows]);

  useEffect(() => {
    if (filterValue == null) {
      setFilter(options.map((v) => ({
        value: v,
        checked: true,
      })));
    }
  }, [filterValue, options, setFilter]);

  const multiSelectValues = (idx) => {
    // eslint-disable-next-line react/prop-types
    setFilter(filterValue.map((v, i) => {
      if (idx === i) {
        return {
          value: v.value,
          checked: !v.checked,
        };
      }
      return v;
    }));
  };

  // Render a multi-select box
  return (
    <CheckBoxSelect options={options} handleChange={multiSelectValues} />
  );
}

// SelectColumnFilter.propTypes = PropTypes.shape({ column: columnPropTypes });

function ProjectsTable({ setData }) {
  const { user } = useUser();
  const [projects, setProjects] = useState([]);

  const formatDates = (rawResponse) => {
    const rawData = rawResponse;
    for (let i = 0; i < rawData.length; i += 1) {
      Object.keys(rawData[i]).forEach((key) => {
        if (key.endsWith('date') && rawData[i][key]) {
          rawData[i][key] = dateTimeStringToFrLocaleDate(rawData[i][key]);
        }
      });
    }
    return rawData;
  };

  useEffect(() => {
    get()
      .then((res) => {
        setProjects(formatDates(res));
      });
  }, [setData]);

  const includesFilter = (rows, id, filterValue) => rows.filter((row) => {
    const rowValue = row.values[id];
    for (let i = 0; i < filterValue.length; i++) {
      if (filterValue[i].value === rowValue) {
        return filterValue[i].checked;
      }
    }
    return false;
  });

  const columns = useMemo(() => [
    {
      Header: 'Libellé',
      accessor: 'name',
    }, {
      Header: 'Code client',
      accessor: 'customer_code',
    }, {
      Header: 'Zone géographique',
      /* eslint-disable arrow-body-style */
      accessor: (originalRow) => {
        return originalRow?.territory
          ? `${originalRow.territory.name} (${originalRow.territory.code})`
          : null;
      },
    }, {
      Header: 'Priorité',
      accessor: 'priority',
      disableFilters: true,
    }, {
      Header: 'Fin de capture',
      accessor: 'capture_end_date',
      disableFilters: true,
    }, {
      Header: 'Date de fin souhaitée',
      accessor: 'desired_end_date',
      sortType: (rowA, rowB, columnId) => {
        const dateA = new Date(rowA.values[columnId].split('-').reverse().join('-'));
        const dateB = new Date(rowB.values[columnId].split('-').reverse().join('-'));
        return dateA.getTime() - dateB.getTime();
      },
      disableFilters: true,
    }, {
      Header: 'Statut',
      accessor: 'status',
      Filter: SelectColumnFilter,
      filter: includesFilter,
    }, {
      Header: 'Matériel',
      accessor: (originalRow) => {
        return originalRow?.devices
          ? `${(new Set(originalRow.devices.map((x) => x.device_id))).size}/${originalRow.device_types.reduce((prev, curr) => prev + curr.count, 0)}`
          : null;
      },
      disableFilters: true,
    }, {
      Header: 'Gestion',
      /* eslint-disable react/no-unstable-nested-components */
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell }) => {
        // eslint-disable-next-line react/prop-types
        const project = cell.row.original;

        // eslint-disable-next-line react/prop-types
        const { id, is_georeferencing } = project;

        return (
          <>
            <Link to={canUpdate(user) && `/projects/${id}`}>
              <button
                aria-label="éditer"
                className="btn btn-primary"
                disabled={!canUpdate(user)}
                type="button"
                title="éditer"
              >
                <PencilSquare />
              </button>
            </Link>
            {' '}
            <ProjectModal project={project} />
            {' '}
            <ProjectReportingModal project={project} />
            {' '}
            <Link to={`/projects/${id}/referential`}>
              <button aria-label="référentiel" className="btn btn-success" type="button" title="référentiel">
                <GeoAltFill />
              </button>
            </Link>
            {' '}
            <Link to={canUpdate(user) && `/projects/${id}/anonymization`}>
              <Button
                disabled={!canUpdate(user)}
                title="anonymisation"
                variant="warning"
              >
                <PersonVideo3 />
              </Button>
            </Link>
            {' '}
            <Link to={canUpdate(user) && `/projects/${id}/triangulation`}>
              <Button
                disabled={!canUpdate(user)}
                title="triangulation"
                variant="warning"
              >
                <PinMapFill />
              </Button>
            </Link>
            {' '}
            <Link to={canUpdate(user) && is_georeferencing && `/projects/${id}/georeferencing`}>
              <Button
                disabled={!canUpdate(user) || !is_georeferencing}
                title="géoréférencement"
                variant="warning"
              >
                <SignpostSplit />
              </Button>
            </Link>
            {' '}
            <button
              aria-label="supprimer"
              className="btn btn-danger"
              onClick={async () => {
                if (window.confirm('Supprimer ?') === true) {
                  await del(id);
                  get()
                    .then((res) => {
                      setProjects(formatDates(res));
                    });
                }
              }}
              disabled={!canDelete(user)}
              type="button"
              title="supprimer"
            >
              <Trash />
            </button>
          </>
        );
      },
    },
  ], [user]);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    /* getTableProps, */
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: useMemo(() => projects, [projects]),
    defaultColumn,
  }, useFilters, useSortBy);

  useEffect(() => {
    setData(rows.map((row) => row.values));
  }, [rows, setData]);

  const getColumnSortIndicator = (col) => {
    if (col.isSorted) {
      if (col.isSortedDesc) {
        return ' 🔽';
      }
      return ' 🔼';
    }
    return '';
  };

  return projects && Array.isArray(projects) ? (
    <Table striped borderless hover>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
              >
                <div {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  <span>
                    {getColumnSortIndicator(column)}
                  </span>
                </div>
                <div>
                  <Form.Text>{column.canFilter ? column.render('Filter') : null}</Form.Text>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  ) : null;
}

ProjectsTable.propTypes = {
  setData: PropTypes.func,
};

ProjectsTable.defaultProps = {
  setData: null,
};

export default ProjectsTable;
