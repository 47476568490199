import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import './pendingVideoCodesPerAcquisitionName.css';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import { extract } from '../common/videocodes';

export default function PendingVideoCodesPerAcquisitionName({ pendingVideosPerAcquisitionName }) {
  return (
    <Table striped borderless hover>
      <thead className="table-head">
        <tr>
          <th className="col-sm-4" scope="col">Fichier CSV de l&apos;acquisition
          </th>
          <th scope="col">Codes des fichiers vidéos manquants <ExclamationTriangle /> </th>
        </tr>
      </thead>
      <tbody className="table-body">
        {pendingVideosPerAcquisitionName.map((item) => (
          <tr key={item.acquisitionName}>
            <td className="col-sm-4">{item.acquisitionName}</td>
            <td>{extract(item.pendingVideoCodes).join(' ')}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

PendingVideoCodesPerAcquisitionName.propTypes = {
  pendingVideosPerAcquisitionName: PropTypes.arrayOf(
    PropTypes.shape({
      acquisitionName: PropTypes.string.isRequired,
      pendingVideoCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};
