import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { useUser } from '../../auth/user';

import {
  canDelete, canUpdate, get, del,
} from './utils';

function DeviceTypesTable() {
  const { user } = useUser();
  const [deviceTypes, setDeviceTypes] = useState([]);
  useEffect(() => {
    get()
      .then((resolvedResponse) => {
        setDeviceTypes(resolvedResponse);
      });
  }, []);

  const deleteDeviceType = async (deviceTypeId) => {
    if (window.confirm('Supprimer ?') === true) {
      await del(deviceTypeId);
      const deviceTypesNew = deviceTypes.filter(
        (element) => element.id !== deviceTypeId,
      );
      setDeviceTypes(deviceTypesNew);
    }
  };

  const rows = deviceTypes && Array.isArray(deviceTypes) ? (
    deviceTypes.map((row) => (
      <tr key={row.name}>
        <td>{row.name}</td>
        <td>
          <Link to={canUpdate(user) && `/devices/types/${row.id}`}>
            <button
              aria-label="éditer"
              className="btn btn-primary"
              disabled={!canUpdate(user)}
              type="button"
            >
              <PencilSquare />
            </button>
          </Link>
          {' '}
          <button
            aria-label="supprimer"
            className="btn btn-danger"
            onClick={() => deleteDeviceType(row.id)}
            disabled={!canDelete(user)}
            type="button"
          >
            <Trash />
          </button>
        </td>
      </tr>
    ))
  ) : (
    // case this.state.data is empty
    <tr>
      <td>aucune donnée</td>
    </tr>
  );

  return (
    <Table striped borderless hover>
      <thead>
        <tr>
          <th>Nom</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default DeviceTypesTable;
