import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilePond } from 'react-filepond';
import { getBearerTokenFromCookie } from '../../common/utils';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

function ReferentialForm({ project_id: projectId }) {
  const [file] = useState();
  const fp = useRef(null);

  return (
    <>
      <h3 className="uploadTitle">Upload réferentiel</h3>
      <FilePond
        files={file}
        allowReorder={false}
        server={
          {
            url: `/datastore/v1.0/projects/${projectId}/referential/upload`,
            process: {
              headers: {
                Authorization: getBearerTokenFromCookie(),
              },
            },
          }
        }
        ref={fp}
        name="file"
        labelIdle='Pas de référentiel trouvé, déposez votre référentiel ici ou <span class="filepond--label-action"> Parcourez votre ordinateur </span>'
        labelInvalidField="Le référentiel est invalide"
        labelFileLoading="Chargement"
        labelFileLoadError="Erreur pendant le chargement"
        labelFileProcessing="Envoi"
        labelFileProcessingComplete="Envoi réussi : fichier en cours de traitement..."
        labelFileProcessingAborted="Envoi annulé"
        labelFileProcessingError="Erreur pendant l'envoi"
        labelFileProcessingRevertError="Erreur pendant l'annulation"
        labelFileRemoveError="Erreur pendant la suppression"
        labelTapToCancel="Cliquez ici pour annuler"
        labelTapToRetry="Cliquez ici pour réessayer"
        labelTapToUndo="Cliquez pour annuler"
        labelButtonRemoveItem="Supprimer"
        labelButtonAbortItemLoad="Annuler le chargement"
        labelButtonRetryItemLoad="Réessayer le chargement"
        labelButtonAbortItemProcessing="Annuler le traitement"
        labelButtonUndoItemProcessing="Défaire le traitement"
        labelButtonRetryItemProcessing="Réessayer le traitement"
        labelButtonProcessItem="Envoyer"
      />
    </>
  );
}

ReferentialForm.propTypes = {
  project_id: PropTypes.string.isRequired,
};

export default ReferentialForm;
