import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { territoryPropType } from './utils';

function TerritorySelector({
  territories, onChange, onBlur, value, isInvalid,
}) {
  return (
    <Form.Select
      aria-label="Zone géographique"
      id="territory_id"
      name="territory_id"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={isInvalid}
    >
      <option value={-1}>Sélectionnez une zone</option>
      {territories
        && territories.map((t) => (
          <option key={t.id} value={t.id}>
            {t.code}
            {' - '}
            {t.name}
          </option>
        ))}
    </Form.Select>
  );
}

TerritorySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  territories: PropTypes.arrayOf(territoryPropType).isRequired,
  isInvalid: PropTypes.bool.isRequired,
};

export default TerritorySelector;
