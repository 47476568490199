import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ButtonLabels } from '../constants';
import ProjectsForm from './singleForm';
import { get, patch } from './utils';
import { dateTimeStringToDate } from '../common/utils';

const formatDates = (resp) => {
  const res = { ...resp };
  Object.keys(resp).forEach((key) => {
    if (key.endsWith('date')) {
      if (res[key]) {
        res[key] = dateTimeStringToDate(res[key]);
      } else {
        res[key] = '';
      }
    }
  });
  return res;
};

function ViewWrapper({ referential }) {
  const { id } = useParams();
  const [project, setProject] = useState({});
  useEffect(() => {
    get(id)
      .then((res) => {
        setProject(formatDates(res));
      });
  }, [id]);

  const submit = patch(id);

  const title = 'Détail du projet';
  return (
    <div>
      <ProjectsForm
        id={id}
        data={project}
        submit={submit}
        title={title}
        submitLabel={ButtonLabels.UPDATE}
        cancelLabel={ButtonLabels.CANCEL}
        referential={referential}
        displayReferential
        isEditing
      />
    </div>
  );
}

ViewWrapper.propTypes = {
  referential: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired,
        }),
      ),
    ),
  }),
};

ViewWrapper.defaultProps = {
  referential: {},
};

export default ViewWrapper;
