import React from 'react';
import { ButtonLabels } from '../constants';
import TerritoryForm from './singleForm';
import { post } from './utils';

function TerritoriesCreate() {
  const title = 'Nouvelle zone géographique';

  return (
    <div>
      <TerritoryForm
        submit={post}
        restrictWrite={false}
        title={title}
        submitLabel={ButtonLabels.SUBMIT}
        cancelLabel={ButtonLabels.CANCEL}
      />
    </div>
  );
}

export default TerritoriesCreate;
