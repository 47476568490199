import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../common/style.css';

function TerritoryForm({
  data,
  submit,
  title,
  submitLabel,
  cancelLabel,
  restrictWrite,
}) {
  const defaultValues = {
    name: '',
    code: '',
  };

  let initValues = defaultValues;
  if (Object.keys(data).length) {
    initValues = { ...defaultValues, ...data };
  }

  const navigate = useNavigate();
  const navigateToTerritories = () => {
    navigate('/territories');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Obligatoire'),
      code: Yup.string().required('Obligatoire'),
    }),
    onSubmit: async (values) => {
      await submit(values);
      navigateToTerritories();
    },
  });

  const confirmNavigation = () => {
    if (!formik.dirty) {
      navigateToTerritories();
    } else {
      const confirm = window.confirm(
        "Certains changements n'ont pas été enregistrés, souhaitez-vous continuer ?",
      );
      if (confirm) {
        navigateToTerritories();
      }
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row className="mt-4">
        <Col md={6}>
          <h3 className="p-3">{title}</h3>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <label htmlFor="name">
              Nom
              {'  '}
              <span>*</span>
              <Form.Control
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isInvalid={!!formik.errors.name}
              />
            </label>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <label htmlFor="code">
              Code
              {'  '}
              <span>*</span>
              <Form.Control
                type="text"
                id="code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
                isInvalid={!!formik.errors.code}
                readOnly={restrictWrite}
              />
            </label>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={2}>
          <button
            onClick={confirmNavigation}
            className="btn btn-outline-danger"
            type="button"
          >
            {cancelLabel}
          </button>
          {' '}
          <button type="submit" className="btn btn-success" disabled={!(formik.isValid && formik.dirty)}>
            {submitLabel}
          </button>
        </Col>
      </Row>
    </Form>
  );
}

TerritoryForm.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  restrictWrite: PropTypes.bool,
};

TerritoryForm.defaultProps = {
  data: {
    name: '',
    code: '',
  },
  restrictWrite: false,
};

export default TerritoryForm;
