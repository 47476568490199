import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import * as projectUtils from '../../project/utils';
import { deviceProjectAssociationPropTypes } from './utils';
import ProjectPicker from '../../project/projectPicker';
import { dateToDateString } from '../../common/utils';
import { get as getDevice } from '../utils';

function LinkedProjectsForm({
  title, submitLabel, cancelLabel, fullValue, submit,
}) {
  const { deviceId } = useParams();

  const [device, setDevice] = useState(null);

  useEffect(() => {
    getDevice(deviceId).then((dev) => setDevice(dev));
  }, [deviceId]);

  const initialValues = {
    ...{
      device_id: deviceId,
      start: '',
      end: '',
      project_id: -1,
    },
    ...fullValue,
  };

  for (let i = 0; i < Object.keys(fullValue).length; i++) {
    const k = Object.keys(fullValue)[i];
    if (fullValue[k] == null) {
      initialValues[k] = '';
    }
  }

  const projectPickerId = 'project_id';

  const nav = useNavigate();
  const navigateToDevices = () => {
    nav(`/devices/${deviceId}/projects`);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      project_id: Yup.number().positive().integer(),
      device_id: Yup.number().positive().integer(),
      start: Yup.date().default(Date.now()),
      end: Yup.date().nullable().default(null),
    }),
    onSubmit: async (values) => {
      const vals = JSON.parse(JSON.stringify(values));
      const keys = Object.keys(vals);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key === 'end' || key === 'start') {
          vals[key] = dateToDateString(vals[key]);
        }
      }
      await submit(deviceId, vals);
      navigateToDevices();
    },
  });

  const confirmNavigation = () => {
    if (formik.dirty) {
      if (window.confirm("Certaines données n'ont pas été sauvegardées, souhaitez-vous quitter ?")) {
        navigateToDevices();
      }
    } else {
      navigateToDevices();
    }
  };

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    projectUtils.get().then((resProj) => {
      setProjects(resProj);
    });
  }, []);

  return (
    <div>
      <Form
        onSubmit={formik.handleSubmit}
        action="/devices/projects"
        className="form-inline"
      >
        <Col className="row justify-content-between">
          <Col md={6}>
            <h3 className="p-3">{title}</h3>
          </Col>

          <Col md={6}>
            <button type="submit" className="btn btn-outline-success float-end">
              {submitLabel}
            </button>
            {' '}
            <button
              onClick={confirmNavigation}
              className="btn btn-outline-danger float-end"
              type="button"
            >
              {cancelLabel}
            </button>
          </Col>
        </Col>
        <Row mt={4} className="mt-4 justify-content-between">
          <Col md={5}>
            <label htmlFor="device_id">
              <Form.Select id="device_id" disabled>
                {device && (
                  <option value={device.id}>
                    {device.hw_identifier}
                  </option>
                )}
              </Form.Select>
            </label>
          </Col>
        </Row>
        <Row mt={4} className="mt-4 justify-content-between">
          <Col md={5}>
            <label htmlFor="start">
              Date d&lsquo;affectation
              <span> * </span>
              <Form.Control
                id="start"
                type="date"
                name="start"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.start}
                isInvalid={!!formik.errors.start
                  || (formik.values.end
                    && (formik.values.end < formik.values.start))}
              />
              {
                formik.touched.start
                && formik.errors.start
                && <div>{formik.errors.start}</div>
              }
            </label>
          </Col>
        </Row>

        <Row mt={4} className="mt-4 justify-content-between">
          <Col md={5}>
            <label htmlFor="end">
              Date de fin d&lsquo;affectation
              <Form.Control
                id="end"
                type="date"
                name="end"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.end}
                isInvalid={!!formik.errors.end
                  || (formik.values.start
                    && (formik.values.end < formik.values.start))}
              />

              {
                formik.touched.end
                && formik.errors.end
                && <div>{formik.errors.end}</div>
              }
            </label>
          </Col>
        </Row>

        <Row mt={4} className="mt-4 justify-content-between">
          <Col md={5}>
            <label htmlFor={projectPickerId}>
              <Col md={12}>
                Projet
                <span>*</span>
                <ProjectPicker
                  id={projectPickerId}
                  value={parseInt(formik.values.project_id, 10)}
                  projects={projects}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.project_id}
                />
              </Col>
              {
                formik.touched.project_id
                && formik.errors.project_id
                && <div>{formik.errors.project_id}</div>
              }
            </label>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

LinkedProjectsForm.propTypes = {
  title: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  fullValue: deviceProjectAssociationPropTypes,
  submit: PropTypes.func.isRequired,
};

LinkedProjectsForm.defaultProps = {
  fullValue: {},
};

export default LinkedProjectsForm;
