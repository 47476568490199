import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonLabels } from '../constants';

import InstitutionForm from './singleForm';
import { get, patch } from './utils';

function InstitutionView() {
  const { id } = useParams();
  const [institution, setInstitution] = useState();

  useEffect(() => {
    get(id)
      .then((response) => {
        setInstitution(response);
      });
  }, [id]);

  const title = 'Modifier un établissement';
  const submit = patch(id);

  return (
    <InstitutionForm
      submit={submit}
      restrictWrite={false}
      title={title}
      data={institution}
      submitLabel={ButtonLabels.UPDATE}
      cancelLabel={ButtonLabels.CANCEL}
    />
  );
}

export default InstitutionView;
