import React, { useEffect, useState, useMemo } from 'react';
import { Table, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { useTable } from 'react-table';
import Decimal from 'decimal.js';
import { SectionStatus } from '../constants';
import { getAdvancement } from './utils';

/* eslint-disable react/jsx-props-no-spreading */

function ReportingView() {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function fetchReporting() {
      const proj = await getAdvancement();
      setProjects(Array.isArray(proj) ? proj : []);
    }
    fetchReporting();
  }, []);

  const getPercentage = (projectRow, sectionStatus) => {
    const { sections } = projectRow;

    const referential = sections.find((x) => x.section_status === 'Référentiel')
      || { section_status: 'Référentiel', length: 0 };
    if (referential.length === 0) return 0;
    const refLength = new Decimal(referential.length);

    let treatedLength = 0;

    //  Ici on considère filmé tout ce qui est dans le csv donc filmé = reférentiel - reste à faire
    if (sectionStatus === SectionStatus.FILMED) {
      const resteAFaire = sections.find((x) => x.section_status === 'Reste à faire')
        || { section_status: 'Reste à faire', length: 0 };
      treatedLength = new Decimal((refLength - resteAFaire.length));
      return treatedLength.dividedBy(refLength).times(100).toDecimalPlaces(2);
    }
    const treatedRow = sections.find((r) => r.section_status === sectionStatus);
    if (treatedRow && referential) {
      treatedLength = new Decimal(treatedRow.length);
      return treatedLength.dividedBy(refLength).times(100).toDecimalPlaces(2);
    }
    return 0;
  };

  const columns = useMemo(() => [
    {
      Header: 'Libellé',
      accessor: 'name',
    },
    {
      Header: 'Code client',
      accessor: 'customer_code',
    }, {
      Header: 'Numéro de devis',
      accessor: 'quote_number',
    }, {
      Header: 'KM',
      accessor: 'length',
    }, {
      Header: 'Fiche initialisation',
      accessor: (projectRow) => (
        projectRow.initialization_form_validated
          ? '100%'
          : '0%'
      ),
    }, {
      Header: 'Validation réferentiel',
      accessor: (projectRow) => (
        projectRow.referential_validated
          ? '100%'
          : '0%'
      ),
    }, {
      Header: 'Filmé',
      accessor: (projectRow) => `${getPercentage(projectRow, SectionStatus.FILMED)}%`,
    }, {
      Header: 'Vidéocodé',
      accessor: (projectRow) => `${getPercentage(projectRow, SectionStatus.VIDEOCODED)}%`,
    }, {
      Header: 'Livrable',
      accessor: (projectRow) => (
        projectRow.delivered
          ? '100%'
          : '0%'
      ),
    },
  ], []);

  const defaultColumn = React.useMemo(
    () => ({
    }),
    [],
  );

  const {
    /* getTableProps, */
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: useMemo(() => projects, [projects]),
    defaultColumn,
  });

  const tableData = useMemo(() => {
    const res = [];
    if (Array.isArray(columns) && columns.length > 0) {
      res.push(columns.map((val) => val.Header));
    }
    if (Array.isArray(rows) && rows.length > 0) {
      res.push(...rows.map((row) => Object.values(row.values)));
    }
    return res;
  }, [rows, columns]);

  if (!Array.isArray(projects) || projects.length === 0) {
    return null;
  }

  return (
    <div>
      <CSVLink data={tableData} filename={`reporting_${new Date().toLocaleDateString()}.csv`}>
        <Button variant="outline-success" className="float-end">
          Export excel
        </Button>
      </CSVLink>
      <Table striped borderless hover>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                >
                  <div>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default ReportingView;
