import PropTypes from 'prop-types';

import AuthCheckerFactory from '../auth/utils';
import { xhr, curry } from '../common/utils';

const objectName = 'institutions';
const { canDelete, canUpdate, canCreate } = AuthCheckerFactory(objectName);

const get = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/institutions`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const pvPatch = (id, data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/institutions/${id}`;
  return xhr('PATCH', data, uri);
};

const patch = curry(pvPatch);

const del = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/institutions/${id}`;
  return xhr('DELETE', null, uri);
};

const post = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/institutions`;
  return xhr('POST', data, uri);
};

const institutionPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const institutionDefaultProps = {};

export {
  post,
  patch,
  get,
  del,
  canUpdate,
  canDelete,
  canCreate,
  institutionPropType,
  institutionDefaultProps,
};
