import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonLabels } from '../../constants';
import { dateTimeStringToDate } from '../../common/utils';
import LinkedProjectsForm from './linkedProjectsForm';
import { get, patch } from './utils';

function LinkedProjectsView() {
  const { deviceId, deviceProjectAssocId } = useParams();
  const [deviceProjectAssociation, setDPA] = useState();

  useEffect(() => {
    get(deviceId, deviceProjectAssocId)
      .then((rawResponse) => {
        const parsedResponse = {
          ...rawResponse,
          ...{
            start: dateTimeStringToDate(rawResponse.start),
            end: dateTimeStringToDate(rawResponse.end),
          },
        };
        setDPA(parsedResponse);
      });
  }, [deviceId, deviceProjectAssocId]);

  const submit = (dId, data) => patch(dId, deviceProjectAssocId, data);

  if (deviceProjectAssociation) {
    return (
      <LinkedProjectsForm
        title="Modifier l&lsquo;affectation"
        submitLabel={ButtonLabels.UPDATE}
        cancelLabel={ButtonLabels.CANCEL}
        fullValue={deviceProjectAssociation}
        submit={submit}
      />
    );
  }
  return null;
}

export default LinkedProjectsView;
