import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import {
  getVideosToValidatedByAcquisition,
  getThumbnail,
  validatedVideo,
  rejectedVideo,
} from '../utils';

function VideoValidation({
  acquisitionId, onAccept, onReject, onDone,
}) {
  const ref = useRef(null);
  const [onLoading, setOnLoading] = useState([true]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoAcquisition, setVideoAcquisition] = useState([]);
  const VideoStatusEnum = {
    UPLOADED: 'Envoyé',
    WAITING_ON_VIDEO: 'En attente d\'envoi',
    VALIDATED: 'Validé',
    UNEXPLOITABLE: 'Inexploitable',
  };

  useEffect(() => {
    getVideosToValidatedByAcquisition(acquisitionId).then((res) => {
      setVideoAcquisition(
        res.filter((row) => row.videofile_upload_status === VideoStatusEnum.UPLOADED),
        setCurrentVideoIndex(0),
      );
    });
  }, [VideoStatusEnum.UPLOADED, acquisitionId]);

  const loadedImage = () => {
    setOnLoading(false);
    ref.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  const getNextVideo = () => {
    if (currentVideoIndex + 1 < videoAcquisition.length) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      onDone(videoAcquisition[currentVideoIndex].acquisitionbatch_id);
    }
  };

  const handleAccept = () => {
    onAccept(acquisitionId);
    setOnLoading(true);
    validatedVideo(videoAcquisition[currentVideoIndex].videofile_id);
    getNextVideo();
  };

  const handleReject = () => {
    onReject(acquisitionId);
    setOnLoading(true);
    rejectedVideo(videoAcquisition[currentVideoIndex].videofile_id);
    getNextVideo();
  };

  return (
    <Container ref={ref}>
      <Row className="d-flex justify-content-center align-items-center">
        <Col>
          {videoAcquisition[currentVideoIndex]
            ? (
              <Card>
                <Card.Header className="text-muted">
                  {videoAcquisition[currentVideoIndex].original_name}
                </Card.Header>
                <Card.Body className="text-muted">
                  <small className="text-muted">
                    {videoAcquisition.length - currentVideoIndex}
                    {' vidéos restantes à valider'}
                  </small>
                </Card.Body>
                <Card.Img
                  variant="top"
                  src={getThumbnail(videoAcquisition[currentVideoIndex].videofile_id)}
                  onLoad={loadedImage}
                />
                <Card.Body>
                  <Row className="d-flex justify-content-between">
                    <Col className="text-center">
                      <Button variant="danger" disabled={onLoading} onClick={handleReject}>Rejeter</Button>
                    </Col>
                    <Col className="text-center">
                      <Button variant="success" disabled={onLoading} onClick={handleAccept}>Accepter</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )
            : ''}
        </Col>
      </Row>
    </Container>
  );
}

VideoValidation.propTypes = {
  acquisitionId: PropTypes.number.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default VideoValidation;
