import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { canUpdateProjectDeviceAssociation, get as getDevice } from '../utils';
import LinkedProjectsTable from './linkedProjectsTable';
import { useUser } from '../../auth/user';

function LinkedProjectsList() {
  const { user } = useUser();
  const { deviceId } = useParams();

  const [device, setDevice] = useState();
  useEffect(() => {
    getDevice(deviceId)
      .then((resolvedResponse) => {
        setDevice(resolvedResponse);
      });
  }, [deviceId]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h3 className="p-3">
            Projets associés au matériel
          </h3>
          <h4>
            {device && ` ${device.hw_identifier} (${device.type.name})`}
          </h4>
        </div>
        <div className="col-md-6">
          {false && (
            <button className="btn btn-outline-info float-end" type="button">
              Exporter
            </button>
          )}
          {canUpdateProjectDeviceAssociation(user) && (
            <Link to={`/devices/${deviceId}/projects/new`}>
              <button className="btn btn-primary float-end" type="button">
                Créer
              </button>
            </Link>
          )}
        </div>
      </div>

      <LinkedProjectsTable />
    </div>
  );
}

export default LinkedProjectsList;
