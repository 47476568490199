import React from 'react';
import PropTypes from 'prop-types';

function VideoCount({ videoCount }) {
  return (
    <>
      {(videoCount.reduce((p, c) => p + c.count, 0))}
      {' / '}
      {videoCount.find((video) => video.videofile_upload_status === 'Envoyé')?.count || '0'}
      {' / '}
      {videoCount.find((video) => video.videofile_upload_status === 'Validé')?.count || '0'}
      {' / '}
      {videoCount.find((video) => video.videofile_upload_status === 'Inexploitable')?.count || '0'}
    </>
  );
}

VideoCount.propTypes = {
  videoCount: PropTypes.arrayOf(PropTypes.shape({
    videofile_upload_status: PropTypes.string,
    count: PropTypes.number,
  })).isRequired,
};
export default VideoCount;
