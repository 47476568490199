import PropTypes from 'prop-types';
import { xhr } from '../../common/utils';
import { projectPropTypes } from '../../project/utils';

const post = (id, data) => xhr('POST', data, `${process.env.REACT_APP_BACKEND_URI}/devices/${id}/projects/`);

const get = (deviceId, dpaId = null) => xhr('GET', null, `${process.env.REACT_APP_BACKEND_URI}/devices/${deviceId}/projects/${dpaId || ''}`);

const del = (deviceId, dpaId) => xhr('DELETE', null, `${process.env.REACT_APP_BACKEND_URI}/devices/${deviceId}/projects/${dpaId}`);

const patch = (deviceId, dpaId, data) => xhr('PATCH', data, `${process.env.REACT_APP_BACKEND_URI}/devices/${deviceId}/projects/${dpaId}`);

const deviceProjectAssociationPropTypes = PropTypes.shape({
  project_id: PropTypes.number,
  device_id: PropTypes.number,
  projects: PropTypes.arrayOf(projectPropTypes),
});

export {
  get, post, del, patch, deviceProjectAssociationPropTypes,
};
