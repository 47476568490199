import * as Yup from 'yup';

export const creationSchema = Yup.object({
  name: Yup.string().required('Obligatoire'),
  quote_number: Yup.string().required('Obligatoire'),
  customer_code: Yup.string().nullable().default(''),
  capture_end_date: Yup.date(),
  desired_end_date: Yup.date().required('Obligatoire').when(
    'capture_end_date',
    ([captureEndDate], schema) => {
      if (captureEndDate !== undefined && captureEndDate !== null) {
        return schema.min(
          captureEndDate,
          'La date de restitution souhaitée doit être supérieure à la date de fin de prise de vue',
        );
      }
      return schema;
    },
  ),
  kit_nb: Yup.number(
    'Le nombre de kits maximal doit être un nombre entier positif.',
  )
    .positive('Le nombre de kits maximal doit être un nombre entier positif.')
    .integer('Le nombre de kits maximal doit être un nombre entier.'),
  territory_id: Yup.number('Veuillez sélectionner une zone géographique.')
    .positive('Veuillez sélectionner une zone géographique.')
    .integer('Veuillez sélectionner une zone géographique.'),
  initialization_form_validated: Yup.bool().required(),
  device_types: Yup.array(),
  referential_validated: Yup.bool().required(),
  delivered: Yup.bool().required(),
  length: Yup.number('La longueur du projet doit être un nombre positif.')
    .positive('La longueur du projet doit être un nombre positif.')
    .required('La longueur du projet est obligatoire.'),
  institution_id: Yup.number(
    'Veuillez sélectionner un établissement partenaire.',
  )
    .positive('Veuillez sélectionner un établissement partenaire.')
    .integer('Veuillez sélectionner un établissement partenaire.'),
  initialization_form_validated_date: Yup.date().nullable().default(null),
  referential_validated_date: Yup.date().nullable().default(null),
  delivered_date: Yup.date().nullable().default(null),
  is_georeferencing: Yup.bool().required(),
});
