import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import { Table } from 'react-bootstrap';
import { canUpdateProjectDeviceAssociation } from '../utils';
import { get, del } from './utils';
import { dateTimeStringToFrLocaleDate } from '../../common/utils';
import { useUser } from '../../auth/user';

function LinkedProjectsTable() {
  const { user } = useUser();
  const { deviceId } = useParams();

  const [linkedProjects, setLinkedProjects] = useState([]);
  useEffect(() => {
    get(deviceId)
      .then((resolvedResponse) => {
        setLinkedProjects(resolvedResponse);
      });
  }, [deviceId]);

  const deleteLinkedProject = async (devId, dpaId) => {
    if (window.confirm('Supprimer ?') === true) {
      await del(devId, dpaId);
      const linkedProjectsNew = linkedProjects.filter(
        (element) => element.id !== dpaId,
      );
      setLinkedProjects(linkedProjectsNew);
    }
  };

  const rows = linkedProjects && Array.isArray(linkedProjects) ? (
    linkedProjects.map((row) => (
      <tr key={`${row.id}_${row.device_id}_${row.project_id}`}>
        <td>{row.project.name}</td>
        <td>{dateTimeStringToFrLocaleDate(row.start)}</td>
        <td>{row.end ? dateTimeStringToFrLocaleDate(row.end) : 'Pas de date de fin'}</td>
        <td>
          <Link to={canUpdateProjectDeviceAssociation(user) && {
            pathname: `/devices/${deviceId}/projects/${row.id}`,
            state: { fullValue: row },
          }}
          >
            <button
              aria-label="éditer"
              className="btn btn-primary"
              disabled={!canUpdateProjectDeviceAssociation(user)}
              type="button"
            >
              <PencilSquare />
            </button>
          </Link>
          {' '}
          <button
            aria-label="supprimer"
            className="btn btn-danger"
            onClick={() => deleteLinkedProject(row.device_id, row.id)}
            disabled={!canUpdateProjectDeviceAssociation(user)}
            type="button"
          >
            <Trash />
          </button>
        </td>
      </tr>
    ))
  ) : (
    // case this.state.data is empty
    <tr>
      <td>aucune donnée</td>
    </tr>
  );

  return (
    <Table striped borderless hover>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Début d&lsquo;affectation au projet</th>
          <th>Fin d&lsquo;affectation au projet</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

export default LinkedProjectsTable;
