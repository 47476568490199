import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Check2Square } from 'react-bootstrap-icons';
import VideoCount from './videoCount';
import VideoValidation from './videoValidation';

function ValidationRow({ acquisition, onValidationDone }) {
  const [showVideo, setShowVideo] = useState(false);
  const [videosStatus, setVideoStatus] = useState(acquisition.videos);
  const VideoStatusEnum = {
    UPLOADED: 'Envoyé',
    WAITING_ON_VIDEO: 'En attente d\'envoi',
    VALIDATED: 'Validé',
    UNEXPLOITABLE: 'Inexploitable',
  };

  const addToStatusCount = (statusToIncrement) => {
    const status = videosStatus.find(
      (video) => video.videofile_upload_status === statusToIncrement,
    );
    if (status) {
      status.count += 1;
    } else {
      videosStatus.push({ videofile_upload_status: statusToIncrement, count: 1 });
    }
    videosStatus.find(
      (video) => video.videofile_upload_status === VideoStatusEnum.UPLOADED,
    ).count -= 1;
    setVideoStatus([...videosStatus]);
  };

  const handleAccept = () => {
    addToStatusCount(VideoStatusEnum.VALIDATED);
  };

  const handleReject = () => {
    addToStatusCount(VideoStatusEnum.UNEXPLOITABLE);
  };

  const handleDone = (id) => {
    onValidationDone(id);
  };

  const getFilename = (acq) => acq.s3_path.split('/').pop();

  return (
    <>
      <tr>
        <td>{getFilename(acquisition)}</td>
        <td>{acquisition.hash}</td>
        <td>{new Date(acquisition.uploaded_on).toLocaleString()}</td>
        <td>
          {(acquisition.status)}
        </td>
        <td aria-label="nombre de video par statut">
          <VideoCount videoCount={videosStatus} />
        </td>
        <td>
          {((acquisition.status) !== 'À valider')
            ? ''
            : (
              <button
                aria-label="afficher les vidéos"
                className="btn btn-primary"
                onClick={() => setShowVideo(true)}
                type="button"
                style={{ alignItems: 'center', marginLeft: '1.2em' }}
              >
                <Check2Square />
              </button>
            )}
        </td>
      </tr>
      {showVideo
        ? (
          <>
            <tr display="none" />
            <tr>
              <td colSpan="5" aria-label="validation">
                <VideoValidation
                  acquisitionId={acquisition.acquisitionbatchs_id}
                  onAccept={handleAccept}
                  onReject={handleReject}
                  onDone={handleDone}
                />
              </td>
            </tr>
          </>
        )
        : null}
    </>
  );
}

ValidationRow.propTypes = {
  acquisition: PropTypes.shape({
    acquisitionbatchs_id: PropTypes.number,
    uploaded_on: PropTypes.string,
    buffer: PropTypes.number,
    s3_path: PropTypes.string,
    hash: PropTypes.string,
    status: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        videofile_upload_status: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }).isRequired,
  onValidationDone: PropTypes.func.isRequired,
};

export default ValidationRow;
