import React from 'react';
import './index.css';
// eslint-disable-next-line import/no-unresolved
import { FlagsProvider } from 'react-feature-flags';
import { createRoot } from 'react-dom/client';
import { flags } from './constants';
import Panoptes from './app';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <FlagsProvider value={flags}>
      <Panoptes />
    </FlagsProvider>
  </React.StrictMode>,
);
