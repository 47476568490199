import React from 'react';
import { Navbar } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { userPropType } from './user';

function AuthMenu(props) {
  const { user } = props;

  if (user) {
    return (
      <Nav className="d.flex">
        <Navbar.Text>
          {user.preferred_username}
          {' '}
          -
          {' '}
          <a href={`${process.env.REACT_APP_BACKEND_URI}/iam/logout`}>
            Déconnexion
          </a>
        </Navbar.Text>
      </Nav>
    );
  }
  return (
    <Nav className="d.flex">
      <a href={`${process.env.REACT_APP_BACKEND_URI}/iam/login`}>Connexion</a>
    </Nav>
  );
}

AuthMenu.propTypes = {
  user: userPropType,
};

AuthMenu.defaultProps = {
  user: null,
};

export default AuthMenu;
