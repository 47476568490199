/* es-lint-disable */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ButtonLabels } from '../../../constants';

function KeyboardDisplay({ keyboard, handleDeleteKeyboard }) {
  const [show, setShow] = useState(false);
  const stringForJsonParse = (JSON.stringify(keyboard.data))
    .replaceAll("'", '"')
    .replaceAll('False', '"false"')
    .replaceAll('"mutable": "false"', '"mutable": false')
    .slice(1, -1);
  // const JPK = JSON.parse(stringForJsonParse);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const onDeleteClick = () => {
    handleDeleteKeyboard(keyboard.id);
    handleCloseModal();
  };

  return (
    <>
      <Button variant="secondary" size="sm" onClick={handleShowModal}>
        {keyboard.name}
      </Button>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Détails du clavier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <code>{stringForJsonParse}</code>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onDeleteClick}>
            {ButtonLabels.DELETE}
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            {ButtonLabels.CANCEL}
          </Button>
        </Modal.Footer>
      </Modal>
      { }
    </>
  );
}

export default KeyboardDisplay;

KeyboardDisplay.propTypes = {
  keyboard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    data: PropTypes.string,
  }),
  handleDeleteKeyboard: PropTypes.func.isRequired,
};

KeyboardDisplay.defaultProps = {
  keyboard: {},
};
