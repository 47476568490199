import * as Yup from 'yup';

const validatorKeyboardSchema = Yup.object().shape({
  keyboard_json: Yup.array('tableau').of(Yup.object().shape({
    name: Yup.string('name doit être de type string').required('name requis'),
    id: Yup.number('id doit être de type number').positive('id doit être positif').integer('id doit être entier'),
    attributes: Yup.array('attributes de type tableau').of(Yup.object().shape({
      id: Yup.number('id doit être de type number').required('id attribut requis').positive('id doit être positif').integer('id doit être entier'),
      name: Yup.string('name doit être de type string').required('name attribut requis'),
      input_type: Yup.string(),
      mutable: Yup.bool(),
      values: Yup.array().of(Yup.string()),
    })),
  })),
  keyboard_name: Yup.string('le nom doit être de type string').required('Veuillez donner un nom au clavier'),
});

export default validatorKeyboardSchema;
