export class InvalidMergedVideoName extends Error {
  constructor(reason: string) {
    super(`Invalid format for merged video name. Reason: ${reason}`);
  }
}

// eslint-disable-next-line camelcase
const YYYY_MM_DD_HH_mm_SS = /\d{4}_\d{1,2}_\d{1,2}_\d{1,2}_\d{1,2}_\d{1,2}/g;

function extractDate(path: string): string {
  const ignoreTreePath = (_: string) => _.split('/').pop();
  const date = ignoreTreePath(path).match(YYYY_MM_DD_HH_mm_SS);
  if (!date) {
    throw new InvalidMergedVideoName('Cannot extract date.');
  }
  return date;
}

export function buildMergedVideoLabel(
  projectName: string,
  videoPath: string,
): string {
  return `${projectName}_${extractDate(videoPath)}`;
}
