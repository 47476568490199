import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Row, Col, Table,
} from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import { PlusCircle, XLg } from 'react-bootstrap-icons';
import '../common/style.css';

function DeviceTypeSelector({ options, projectId }) {
  const formik = useFormikContext();

  const [remainingOptions, setRemainingOptions] = useState([]);

  useEffect(() => {
    const value = formik?.values?.device_types;
    if (value && value.length > 0) {
      const usedOptions = value.map((v) => v?.device_type_id);
      const res = options.filter((opt) => !usedOptions.includes(opt.id));
      setRemainingOptions(res);
    } else {
      setRemainingOptions(options);
    }
  }, [options, formik.values.device_types]);

  const [currentValue, setCurrentValue] = useState(-1);

  const [currentCount, setCurrentCount] = useState(0);

  const handleSelectChange = (e) => {
    const v = parseInt(e.target.value, 10);
    const fullValue = options.find((opt) => (opt.id === v));
    setCurrentValue(fullValue);
  };

  const handleCountChange = (e) => {
    const v = parseInt(e.target.value, 10);
    setCurrentCount(v);
  };

  return (
    <FieldArray
      name="device_types"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      render={(arrayHelpers) => (
        <>
          <Row>
            <Col md={2}>
              <Form.Control
                value={currentCount}
                type="number"
                onChange={handleCountChange}
              />
            </Col>
            <Col md={8}>
              <Form.Select aria-label="Type de kits" onChange={handleSelectChange}>
                <option key="title" value="-1">
                  Type de kit
                </option>
                {remainingOptions.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={2}>
              <Button
                variant="outline-dark"
                onClick={() => {
                  if (currentValue !== -1) {
                    arrayHelpers.push({
                      count: currentCount,
                      device_type_id: currentValue.id,
                      name: currentValue.name,
                      project_id: projectId,
                    });
                    setCurrentValue(-1);
                    setCurrentCount(0);
                  }
                }}
              >
                <PlusCircle />
              </Button>
            </Col>
          </Row>
          {formik.values.device_types && formik.values.device_types.length > 0 && (
            <Table hover size="sm" className="device-type-list">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Type</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.device_types.map((v, index) => (
                  <tr key={v.device_type_id}>
                    <td>
                      <Form.Control
                        aria-label="ajouter"
                        value={v.count}
                        name={`device_types[${index}].count`}
                        type="number"
                        onChange={formik.handleChange}
                      />
                    </td>
                    <td>
                      {options && options.find((x) => x.id === v.device_type_id).name}
                    </td>
                    <td>
                      <Button aria-label="supprimer" variant="outline-danger" onClick={() => arrayHelpers.remove(index)}>
                        <XLg />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    />
  );
}

DeviceTypeSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
  projectId: PropTypes.number,
};

DeviceTypeSelector.defaultProps = {
  options: [],
  projectId: null,
};

export default DeviceTypeSelector;
