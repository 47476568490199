import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import './about.css';

const applicationVersionComparator = (
  applicationVersion,
  anotherApplicationVersion,
) => (applicationVersion.component_name.localeCompare(anotherApplicationVersion.component_name));

export default function About(props) {
  const { show, onHide, applicationsVersions } = props;

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Versions applicatives
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="application-version-line">
        {
          applicationsVersions.sort(applicationVersionComparator).map((applicationVersion) => (
            <React.Fragment key={applicationVersion.component_name}>
              <div className="application-name">{applicationVersion.component_name}</div>
              <div className="application-version-text">{applicationVersion.version}</div>
            </React.Fragment>
          ))
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          aria-label="fermer la popup à propos"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

About.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  applicationsVersions: PropTypes.arrayOf(PropTypes.shape({
    component_name: string,
    version: string,
  })).isRequired,
};
