import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import ReferentialForm from './referentialForm';
import ReferentialViewer from './referentialViewer';
import { ReferentialStatus } from '../../constants';

function ReferentialWrapper() {
  const { id } = useParams();
  const [referential, setReferential] = useState(null);
  const [referentialExists, setReferentialExists] = useState(true);
  const [referentialStatus, setReferentialStatus] = useState();
  const [bounds, setBounds] = useState([[], []]);

  useEffect(() => {
    const fetchReferential = async () => {
      const requestOptions = {
        method: 'GET',
      };

      const rawResponse = fetch(
        `${process.env.REACT_APP_BACKEND_URI}/projects/${id}/referential/status`,
        requestOptions,
      );
      return rawResponse;
    };
    if (id != null) {
      fetchReferential().then((response) => {
        if (response.status === 404) {
          setReferentialExists(false);
        } else {
          response.json().then((status) => {
            setReferentialExists(true);
            setReferentialStatus(status);
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const fetchReferential = async () => {
      const requestOptions = {
        method: 'GET',
      };

      const rawResponse = fetch(
        `${process.env.REACT_APP_BACKEND_URI}/projects/${id}/referential`,
        requestOptions,
      );
      return rawResponse;
    };
    if (id != null) {
      fetchReferential()
        .then((res) => {
          if (res.status >= 400) {
            setReferential(null);
          } else {
            res.json().then((resolvedResponse) => {
              setReferential(resolvedResponse.filter((r) => r.geometry));
            });
          }
        });
    }
  }, [id]);

  const [arrs, setArrs] = useState([]);

  const [done, setDone] = useState(false);

  useEffect(() => {
    const makeArrays = (r) => {
      const res = {};
      for (let i = 0; i < r.length; i++) {
        const currtSection = r[i];
        let geometry = JSON.parse(currtSection.geometry);
        let key = currtSection.section_status;
        if (res[key] == null) {
          res[key] = [];
        }
        if (geometry) {
          res[key].push(
            geometry.coordinates.map((c) => ({ lng: c[0], lat: c[1] })),
          );
        }
        if (currtSection.section_status === 'Référentiel' && currtSection.raw_geometry) {
          geometry = JSON.parse(currtSection.raw_geometry);
          key = 'raw_referential';
          if (res[key] == null) {
            res[key] = [];
          }
          res[key].push(
            geometry.coordinates.map((c) => ({ lng: c[0], lat: c[1] })),
          );
        }
      }
      return res;
    };

    if (referential) {
      const arrsval = makeArrays(referential);

      setArrs(arrsval);
    }
  }, [referential, bounds]);

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    const findMinMax = (a) => {
      const key = Object.keys(a)[0];
      const todoArr = a[key];

      // init minMax, finding the first not empty
      let n = 0;
      while (!todoArr[n][0] || !todoArr[n][0].lat || !todoArr[n][0].lng) {
        n += 1;
      }
      const minMax = [
        [todoArr[n][0].lat, todoArr[n][0].lng],
        [todoArr[n][0].lat, todoArr[n][0].lng],
      ];

      for (let i = 0; i < todoArr.length; i++) {
        for (let j = 0; j < todoArr[i].length; j++) {
          const currentPolyline = todoArr[i][j];
          if (currentPolyline.lat
            && (currentPolyline.lat - 0.03) < minMax[0][0]) {
            minMax[0][0] = currentPolyline.lat - 0.03;
          }
          if (currentPolyline.lng
            && currentPolyline.lng < minMax[0][1]) {
            minMax[0][1] = currentPolyline.lng;
          }
          if (currentPolyline.lat && (currentPolyline.lat + 0.03) > (minMax[1][0])) {
            minMax[1][0] = currentPolyline.lat + 0.03;
          }
          if (currentPolyline.lng && currentPolyline.lng > minMax[1][1]) {
            minMax[1][1] = currentPolyline.lng;
          }
        }
      }
      return minMax;
    };

    if (
      arrs
      && Object.keys(arrs)
      && Object.keys(arrs)[0]
      && arrs[Object.keys(arrs)[0]].length
      && !done
    ) {
      const minMax = findMinMax(arrs);
      setBounds(minMax);
      setCenter({
        lat: (minMax[0][0] + minMax[0][1]) / 2,
        lng: (minMax[1][0] + minMax[1][1]) / 2,
      });
      setDone(true);
    }
  }, [arrs, done]);

  if (referentialStatus === ReferentialStatus.IN_PROGRESS) {
    return <div> En cours d&apos;analyse, veuillez revenir ultérieurement </div>;
  }

  if (!referentialExists) {
    return <ReferentialForm project_id={id} />;
  }

  if (referential) {
    if (done) {
      return (
        <ReferentialViewer
          referential={referential}
          center={center}
          sections={arrs}
          bounds={bounds}
        />
      );
    }
    return null;
  }
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default ReferentialWrapper;
