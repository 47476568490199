import AuthCheckerFactory from '../../auth/utils';
import { xhr, curry } from '../../common/utils';

const objectName = 'devices';
const { canDelete, canUpdate, canCreate } = AuthCheckerFactory(objectName);

const get = (id) => {
  let uri = `${process.env.REACT_APP_BACKEND_URI}/device_types`;
  if (id != null) {
    uri += `/${id}`;
  }
  return xhr('GET', null, uri);
};

const pvPatch = (id, data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/device_types/${id}`;
  return xhr('PATCH', data, uri);
};

const patch = curry(pvPatch);

const del = (id) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/device_types/${id}`;
  return xhr('DELETE', null, uri);
};

const post = (data) => {
  const uri = `${process.env.REACT_APP_BACKEND_URI}/device_types`;
  return xhr('PATCH', data, uri);
};

export {
  get, patch, canDelete, canUpdate, canCreate, del, post,
};
